.ma-title {
  font-size: 20px;
  line-height: 50px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: #990000;
  display: table;
}
.border_bottom {
  border-bottom: 1px solid black;
}
.border_bottomstyle {
  border-bottom: 1px dashed black;
}
.subheading {
  color: #000000;

  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.subheading1 {
  display: table;
  /* margin: auto; */
  line-height: 50px;
  font-size: 18px;
}
.suburls {
  line-height: 21px;
  text-decoration: underline;
  color: black;
}
.imgsize {
  width: 800px;
}
.color {
  color: black;
  font-size: 16px;
}
.ma_sidebar_color {
  color: black;
}
.ma_sidebar_color a:active {
  color: #33cbfc;
}
.setPosition {
  position: absolute;
  bottom: 20px;
}
.uppercase {
  text-transform: uppercase;
}

.borderstyle {
  border: 2px solid #797979;
}

@media only screen and (max-width: 600px) {
  .imgsize {
    width: 358px;
  }
}
