@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Roboto:300,400,500,700&display=swap");
/* mixin for multiline */
body {
  font-family: "Open Sans";
  color: #141414;
  font-size: 0.9375rem;
}

a {
  color: #1ab6df;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif;
}

h2,
h3 {
  color: #010101;
  font-weight: 700;
}

h3 {
  font-size: 1.1875rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media only screen and (min-width: 991px) {
  .pdg100 {
    padding: 0 100px;
  }
}

hr {
  background: rgba(0, 0, 0, 0.2);
}

.pdg15 {
  padding: 15px;
}

.font-14 {
  font-size: 0.875rem;
}

.font-15 {
  font-size: 0.9375rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-28 {
  font-size: 1.75rem;
}

.font-26 {
  font-size: 1.625rem;
}

.font500 {
  font-weight: 500;
}

.font600 {
  font-weight: 600;
}
.btext {
  color: #1f419b;
}

.shadow {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.fs12rem {
  font-size: 1.2rem !important;
}

.font700 {
  font-weight: 700;
}

.basec-opa85 {
  color: rgba(20, 20, 20, 0.85);
}
.menuIconBar button {
  padding: 0 !important;
}

.bdrpdg {
  padding: 30px 40px;
  border: 1px solid rgba(20, 20, 20, 0.1);
}

.mt-22 {
  margin-top: 22px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

a {
  -webkit-transition: all 0.3s cubic-bezier(0.32, 0.74, 0.57, 1);
  transition: all 0.3s cubic-bezier(0.32, 0.74, 0.57, 1);
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  text-decoration-color: transparent;
  -webkit-text-decoration-color: transparent;
  -moz-text-decoration-color: transparent;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-blue {
  background: #1ab6df !important;
  color: #fff;
  padding: 0.375rem 1.9rem !important;
}

.btn-blue:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-blue:active {
  background: #0d596d;
}
.w-100px {
  width: 100px;
}

@media only screen and (max-width: 399px) {
  .btn-blue {
    padding: 0.375rem 1rem;
  }
}

.btn-blue:hover {
  color: #fff;
}

.btn-blue.btn-lg {
  padding: 0.7rem 1rem !important;
  font-weight: 600;
}

input.placeholder,
option.placeholder,
select.placeholder,
textarea.placeholder {
  color: #141414;
  font-size: 14px !important;
}

input:-moz-placeholder,
option:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
  color: #141414;
  font-size: 14px !important;
}

input::-moz-placeholder,
option::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  color: #141414;
  font-size: 14px !important;
}

input:-ms-input-placeholder,
option:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #141414;
  font-size: 14px !important;
}

input::-webkit-input-placeholder,
option::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #141414;
  font-size: 14px !important;
}

.form-control {
  border-radius: 0;
  font-size: 15px;
  padding: 0.7rem 0.75rem !important;
  height: auto;
  border: 1px solid #b7b7b7;
}

.btnLink-blue {
  display: inline-block;
  background: #1ab6df;
  font-family: "Roboto";
  color: #fff !important;
  padding: 3px 10px;
  text-decoration: none;
  font-weight: 600;
}
.companyname {
  float: right;
}

.companyimage {
  max-width: 70px;
}

.clogo-s {
  width: 107px;
  height: auto;
  margin-bottom: 5px;
}
.btnLink-blue:hover {
  color: #fff;
  text-decoration: none;
  background: #1f419b;
  cursor: pointer;
}

.btnLink-blue img {
  width: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.greyborder2px {
  border: 2px solid #dbdada;
}
.greyborder1_4px {
  border: 1.4px solid #dbdada;
}
.greyborder1_4px_bottom {
  border-bottom: 1.4px solid #dbdada;
}
.w-70p,
.w-30p {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.w-70p.col,
.w-30p.col {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
  .companyimage {
    float: right;
    max-width: 70px;
  }
  .container-fluid {
    max-width: 95%;
  }
  .para-text {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

/* @media (max-width: 1200px) {
  .subscribe-list-points {
    display: none;
  }
} */

@media (min-width: 992px) {
  .w-70p {
    -ms-flex: 0 0 73%;
    -webkit-box-flex: 0;
    flex: 0 0 73%;
    max-width: 73%;
  }
  .w-30p {
    -ms-flex: 0 0 27%;
    -webkit-box-flex: 0;
    flex: 0 0 27%;
    max-width: 27%;
  }
}

.two-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.two-item .two-content-holder {
  width: 100%;
}

@media only screen and (max-width: 990px) {
  .two-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .companyimage {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 990px) {
  .two-item .two-image-holder .image-g {
    width: 100%;
  }
}

.widget {
  margin: 0 0 42px;
}

.capchaCode img {
  height: 80px;
}

.wct119 {
  width: 119px;
  height: 100%;
}

.irs h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.irs .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

ul.ticklist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.ticklist.font-weight-normal li {
  font-weight: 500;
}

ul.ticklist li {
  background: url("../src/assets/images/right-sign.png") no-repeat left 6px;
  padding-left: 25px;
  margin: 5px 0;
  font-weight: 700;
}

ul.subscribe-list li {
  background: url("../src/assets/images/tick-mark.png") no-repeat left 6px;
  background-size: 16px;
  padding-left: 25px;
  margin: 5px 0;
  font-weight: 700;
}

ul.subscribe-points li {
  background: url("../src/assets/images/tick-mark.png") no-repeat left 6px;
  background-size: 16px;
  padding-left: 25px;
  margin: 5px 0;
  font-weight: 700;
}

ul.ticklist li ul li {
  font-weight: normal;
  background: none;
  position: relative;
  padding-left: 0;
  list-style: none;
}

ul.ticklist li ul li::before {
  position: absoulte;
  content: ">>";
  left: 0;
  padding-right: 10px;
  font-weight: 700;
}

ul.ticklist li ul li:last-child {
  margin-bottom: 15px;
}

.blew-box {
  background: #c6ecf6;
  padding: 15px;
}

.newstag {
  color: rgba(20, 20, 20, 0.73);
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "Roboto";
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms linear 150ms;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.autoc div {
  width: 100%;
}

.autoc div .menu {
  position: absolute;
  background: #fff;
  z-index: 999;
  border: 1px solid #eee;
}

.autoc div .menu .item {
  border-bottom: 1px solid #eee;
  padding: 10px 15px;
}

.autoc div .menu .item:hover {
  background: #1ab6df;
  color: #fff;
}

.autoc input {
  font-size: 0.9375rem;
  border-radius: 0;
  padding: 0.7rem 0.75rem !important;
  height: auto;
  border: 1px solid #b7b7b7;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.autoc input:focus {
  outline: 0;
  border: 1px solid #1ab6df;
}

@media (max-device-width: 1024px) {
  .showInDesktop {
    display: none;
  }
}

@media (min-device-width: 1025px) {
  .showInMobile {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .news-module .news-content h3 {
    font-size: 1.1rem;
  }
  .row-1,
  .row-2 {
    flex-wrap: nowrap;
  }
  .companyname {
    float: right;
  }
}
@media only screen and (min-width: 1084px) {
  .floatShow {
    display: none;
  }
}
.p-4test {
  padding: 0 !important;
}
.ph-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
}
.bdrph {
  border: 1px solid #eee;
  padding: 10px 0.25rem 0 0;
}
.filterph {
  height: 42px !important;
  border-radius: 3px;
}
.ph-item,
.ph-item *,
.ph-item ::after,
.ph-item ::before {
  box-sizing: border-box;
}
.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 46%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(255, 255, 255, 0) 54%
    )
    50% 50%;
}
.ph-item > * {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}
.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #e5e5e5;
}
.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}
.ph-row .news-big {
  height: 30px;
}
.ph-row .empty {
  background-color: rgba(255, 255, 255, 0);
}

.ph-col-2 {
  flex: 0 0 16.66667%;
}

.ph-col-4 {
  flex: 0 0 33.33333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.66667%;
}

.ph-col-10 {
  flex: 0 0 83.33333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #e5e5e5;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}
.ph-avatar::before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-picture {
  width: 100%;
  height: 158px;
  background-color: #e5e5e5;
}
.ph-picture-NB {
  width: 100%;
  height: 500px;
  background-color: #e5e5e5;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

@media only screen and (min-width: 991px) {
  #states-autocomplete {
    width: 323px;
  }
}
@media only screen and (max-width: 767px) {
  #states-autocomplete {
    display: none;
  }
}
#states-autocomplete {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.autoc input.nofcs:focus {
  border: none;
}
.autoc .menu .item {
  color: #141414;
}
.cookieConsent {
  padding: 1.2em;
  border-radius: 5px;
  max-width: 24em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  bottom: 1em !important;
  right: 1em;
  left: auto !important;
  top: auto;
}
.cookieConsent a:hover {
  color: #fff;
}
.cokielist {
  list-style-type: disc;
  padding-left: 17px;
  margin-bottom: 30px;
}
.cokielist li {
  padding: 5px 0;
}
.cookies-btn {
}
.cc-btn {
  display: inline-block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 5px !important;
  line-height: 1.5em !important;
  padding: 0.6em 0.8em !important;
  font-size: 1em !important;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  margin: 0 auto !important;
}
.cc-btn:last-child {
  min-width: 140px;
}
.cookieConsent div {
  flex: 1 !important;
}

@media only screen and (max-width: 768px) {
  .cookieConsent {
    right: auto !important;
  }
  .companyname {
    float: left;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 15px;
  }
  .p-4test {
    padding: 1.5rem !important;
  }
  .block-date {
    display: block;
  }
  .mb-3 {
    margin-bottom: 3rem !important;
  }
  .floatHide {
    display: none;
  }
  .floatRight {
    margin-bottom: 5px;
  }
  .banner-container {
    top: 5%; 
    left: 5%; 
    background-color: rgba(255,255,255, 0.8); 
    background-repeat: no-repeat; 
    width: 90%; 
    padding: 10px;
    height: auto; 
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); 
  }
  .heading-text-bold {
    font-size: 13px;
  }
  .price-text-bold {
    font-size: 13px;
    color: rgb(0, 32, 96);
  }
  .para-text {
    font-size: 11px;
    margin-bottom: 10px;
  }
  .margin-top-banner {
    margin-top: 0.2rem;
  }
  .margin-bottom-banner {
    margin-bottom: 0rem;
  }
  .banner-icon {
    width: 30px;
  }
  .banner-btn {
    font-size: 10px;
  }
  .small-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 8px;
  }
  .limited-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 7px;
  }
}
@media only screen and (min-width: 768px) and (max-device-width: 1199px) {
  .margin-bottom-banner {
    margin-bottom: 0rem;
  }
  .para-text {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .subscription-demo-left {
    display: none;
  }
}
@media (max-width: 1199px) {
  .subscribe-list {
    display: none;
  }
}
@media (min-width: 1200px) {
  .subscription-demo-right {
    display: none;
  }
}
@media only screen and (min-width: 375px) and (max-device-width: 520px) {
  .banner-container {
    background-color: rgba(255,255,255, 0.8); 
    background-repeat: no-repeat; 
    width: 90%; 
    padding: 8px;
    height: auto; 
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    line-height: 1;
  }
  .heading-text-bold {
    font-size: 11px;
  }
  .price-text-bold {
    font-size: 11px;
    color: rgb(0, 32, 96);
  }
  .para-text {
    font-size: 8px;
    margin-bottom: 10px;
  }
  .banner-btn {
    font-size: 8px;
  }
  .limited-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 5px;
  }
  .small-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 6px;
  }
  .banner-border-bottom {
    border-bottom: 2px solid rgba(26,182,223,0.1);
  }
  .subscription-demo-left {
    display: none;
  }
}
@media only screen and (min-width: 521px) and (max-device-width: 768px) {
  .banner-container {
    background-color: rgba(255,255,255, 0.8); 
    background-repeat: no-repeat; 
    width: 90%; 
    padding: 15px;
    height: auto; 
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    line-height: 1.5;
  }
  .heading-text-bold {
    font-size: 12px;
  }
  .price-text-bold {
    font-size: 13px;
    color: rgb(0, 32, 96);
  }
  .para-text {
    font-size: 10px;
    margin-bottom: 10px;
  }
  .banner-btn {
    font-size: 8px;
  }
  .limited-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 5px;
  }
  .small-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 6px;
  }
  .banner-border-bottom {
    border-bottom: 2px solid rgba(26,182,223,0.1);
  }
  .subscription-demo-left {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .banner-container {
    background-color: rgba(255,255,255, 0.8); 
    background-repeat: no-repeat; 
    width: 90%; 
    padding: 20px;
    height: auto; 
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .banner-border-bottom {
    border-bottom: 4px solid rgba(26,182,223,0.1);
  }
  .heading-text-bold {
    font-size: 18px;
  }
  .price-text-bold {
    font-size: 18px;
    color: rgb(0, 32, 96);
  }
  .margin-top-banner {
    margin-top: 1rem;
  }
  .banner-icon {
    width: 40px;
  }
  .small-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 12px;
  }
  .limited-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 10px;
  }
}

.col-md-10 {
  display: none;
}
/*# sourceMappingURL=App.css.map */
.col-auto {
  display: block;
}

.purchase-subscription-button {
  background: rgb(26 82 156);
  color: white;
  width: 40%;
}
.purchase-subscription-button:hover {
  background: rgb(26 82 156);
  color: white;
  width: 40%;
}

.StripeElement {
  border: 1px solid #b7b7b7;
  padding: 12px;
  margin-bottom: 16px;
  width: 100%;
}