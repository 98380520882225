@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Roboto:300,400,500,700&display=swap);

/* mixin for multiline */
body {
  font-family: "Open Sans";
  color: #141414;
  font-size: 0.9375rem;
}

a {
  color: #1ab6df;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif;
}

h2,
h3 {
  color: #010101;
  font-weight: 700;
}

h3 {
  font-size: 1.1875rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media only screen and (min-width: 991px) {
  .pdg100 {
    padding: 0 100px;
  }
}

hr {
  background: rgba(0, 0, 0, 0.2);
}

.pdg15 {
  padding: 15px;
}

.font-14 {
  font-size: 0.875rem;
}

.font-15 {
  font-size: 0.9375rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-28 {
  font-size: 1.75rem;
}

.font-26 {
  font-size: 1.625rem;
}

.font500 {
  font-weight: 500;
}

.font600 {
  font-weight: 600;
}
.btext {
  color: #1f419b;
}

.shadow {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.fs12rem {
  font-size: 1.2rem !important;
}

.font700 {
  font-weight: 700;
}

.basec-opa85 {
  color: rgba(20, 20, 20, 0.85);
}
.menuIconBar button {
  padding: 0 !important;
}

.bdrpdg {
  padding: 30px 40px;
  border: 1px solid rgba(20, 20, 20, 0.1);
}

.mt-22 {
  margin-top: 22px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

a {
  transition: all 0.3s cubic-bezier(0.32, 0.74, 0.57, 1);
  text-decoration-line: underline;
  text-decoration-color: transparent;
  -webkit-text-decoration-color: transparent;
  -moz-text-decoration-color: transparent;
}

.form-control:focus {
  box-shadow: none;
}

.btn-blue {
  background: #1ab6df !important;
  color: #fff;
  padding: 0.375rem 1.9rem !important;
}

.btn-blue:hover {
  transition: all 0.3s ease;
}

.btn-blue:active {
  background: #0d596d;
}
.w-100px {
  width: 100px;
}

@media only screen and (max-width: 399px) {
  .btn-blue {
    padding: 0.375rem 1rem;
  }
}

.btn-blue:hover {
  color: #fff;
}

.btn-blue.btn-lg {
  padding: 0.7rem 1rem !important;
  font-weight: 600;
}

input.placeholder,
option.placeholder,
select.placeholder,
textarea.placeholder {
  color: #141414;
  font-size: 14px !important;
}

input:-moz-placeholder,
option:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
  color: #141414;
  font-size: 14px !important;
}

input::-moz-placeholder,
option::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  color: #141414;
  font-size: 14px !important;
}

input:-ms-input-placeholder,
option:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #141414;
  font-size: 14px !important;
}

input::-webkit-input-placeholder,
option::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #141414;
  font-size: 14px !important;
}

.form-control {
  border-radius: 0;
  font-size: 15px;
  padding: 0.7rem 0.75rem !important;
  height: auto;
  border: 1px solid #b7b7b7;
}

.btnLink-blue {
  display: inline-block;
  background: #1ab6df;
  font-family: "Roboto";
  color: #fff !important;
  padding: 3px 10px;
  text-decoration: none;
  font-weight: 600;
}
.companyname {
  float: right;
}

.companyimage {
  max-width: 70px;
}

.clogo-s {
  width: 107px;
  height: auto;
  margin-bottom: 5px;
}
.btnLink-blue:hover {
  color: #fff;
  text-decoration: none;
  background: #1f419b;
  cursor: pointer;
}

.btnLink-blue img {
  width: 10px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.greyborder2px {
  border: 2px solid #dbdada;
}
.greyborder1_4px {
  border: 1.4px solid #dbdada;
}
.greyborder1_4px_bottom {
  border-bottom: 1.4px solid #dbdada;
}
.w-70p,
.w-30p {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.w-70p.col,
.w-30p.col {
  flex-basis: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
  .companyimage {
    float: right;
    max-width: 70px;
  }
  .container-fluid {
    max-width: 95%;
  }
  .para-text {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

/* @media (max-width: 1200px) {
  .subscribe-list-points {
    display: none;
  }
} */

@media (min-width: 992px) {
  .w-70p {
    flex: 0 0 73%;
    max-width: 73%;
  }
  .w-30p {
    flex: 0 0 27%;
    max-width: 27%;
  }
}

.two-item {
  display: flex;
  box-sizing: border-box;
}

.two-item .two-content-holder {
  width: 100%;
}

@media only screen and (max-width: 990px) {
  .two-item {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .companyimage {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 990px) {
  .two-item .two-image-holder .image-g {
    width: 100%;
  }
}

.widget {
  margin: 0 0 42px;
}

.capchaCode img {
  height: 80px;
}

.wct119 {
  width: 119px;
  height: 100%;
}

.irs h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.irs .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

ul.ticklist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.ticklist.font-weight-normal li {
  font-weight: 500;
}

ul.ticklist li {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAANCAYAAAB2HjRBAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAItJREFUeNpiYKA3EBcXNwDi94xkaBQAUueBeCI5tu4H4vkgNhOJGhuAFMjmQlJtDAD5E+RfsgIIZAC2AMAbQEB8HupkFADycz1UEpch/UD84eXLl5iagYIgz18A4vvo/gHyC4CUAxAHEvJXAhD/B9FQvgOUb0BqwMyH0gn41DPiSEH7of50xKcZIMAACd4o/p/ArccAAAAASUVORK5CYII=) no-repeat left 6px;
  padding-left: 25px;
  margin: 5px 0;
  font-weight: 700;
}

ul.subscribe-list li {
  background: url(/static/media/tick-mark.a5b0d414.png) no-repeat left 6px;
  background-size: 16px;
  padding-left: 25px;
  margin: 5px 0;
  font-weight: 700;
}

ul.subscribe-points li {
  background: url(/static/media/tick-mark.a5b0d414.png) no-repeat left 6px;
  background-size: 16px;
  padding-left: 25px;
  margin: 5px 0;
  font-weight: 700;
}

ul.ticklist li ul li {
  font-weight: normal;
  background: none;
  position: relative;
  padding-left: 0;
  list-style: none;
}

ul.ticklist li ul li::before {
  position: absoulte;
  content: ">>";
  left: 0;
  padding-right: 10px;
  font-weight: 700;
}

ul.ticklist li ul li:last-child {
  margin-bottom: 15px;
}

.blew-box {
  background: #c6ecf6;
  padding: 15px;
}

.newstag {
  color: rgba(20, 20, 20, 0.73);
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "Roboto";
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.autoc div {
  width: 100%;
}

.autoc div .menu {
  position: absolute;
  background: #fff;
  z-index: 999;
  border: 1px solid #eee;
}

.autoc div .menu .item {
  border-bottom: 1px solid #eee;
  padding: 10px 15px;
}

.autoc div .menu .item:hover {
  background: #1ab6df;
  color: #fff;
}

.autoc input {
  font-size: 0.9375rem;
  border-radius: 0;
  padding: 0.7rem 0.75rem !important;
  height: auto;
  border: 1px solid #b7b7b7;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autoc input:focus {
  outline: 0;
  border: 1px solid #1ab6df;
}

@media (max-device-width: 1024px) {
  .showInDesktop {
    display: none;
  }
}

@media (min-device-width: 1025px) {
  .showInMobile {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .news-module .news-content h3 {
    font-size: 1.1rem;
  }
  .row-1,
  .row-2 {
    flex-wrap: nowrap;
  }
  .companyname {
    float: right;
  }
}
@media only screen and (min-width: 1084px) {
  .floatShow {
    display: none;
  }
}
.p-4test {
  padding: 0 !important;
}
.ph-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
}
.bdrph {
  border: 1px solid #eee;
  padding: 10px 0.25rem 0 0;
}
.filterph {
  height: 42px !important;
  border-radius: 3px;
}
.ph-item,
.ph-item *,
.ph-item ::after,
.ph-item ::before {
  box-sizing: border-box;
}
.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation 0.8s linear infinite;
          animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 46%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(255, 255, 255, 0) 54%
    )
    50% 50%;
}
.ph-item > * {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}
.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #e5e5e5;
}
.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}
.ph-row .news-big {
  height: 30px;
}
.ph-row .empty {
  background-color: rgba(255, 255, 255, 0);
}

.ph-col-2 {
  flex: 0 0 16.66667%;
}

.ph-col-4 {
  flex: 0 0 33.33333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.66667%;
}

.ph-col-10 {
  flex: 0 0 83.33333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #e5e5e5;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}
.ph-avatar::before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-picture {
  width: 100%;
  height: 158px;
  background-color: #e5e5e5;
}
.ph-picture-NB {
  width: 100%;
  height: 500px;
  background-color: #e5e5e5;
}

@-webkit-keyframes phAnimation {
  0% {
    -webkit-transform: translate3d(-30%, 0, 0);
            transform: translate3d(-30%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(30%, 0, 0);
            transform: translate3d(30%, 0, 0);
  }
}

@keyframes phAnimation {
  0% {
    -webkit-transform: translate3d(-30%, 0, 0);
            transform: translate3d(-30%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(30%, 0, 0);
            transform: translate3d(30%, 0, 0);
  }
}

@media only screen and (min-width: 991px) {
  #states-autocomplete {
    width: 323px;
  }
}
@media only screen and (max-width: 767px) {
  #states-autocomplete {
    display: none;
  }
}
#states-autocomplete {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.autoc input.nofcs:focus {
  border: none;
}
.autoc .menu .item {
  color: #141414;
}
.cookieConsent {
  padding: 1.2em;
  border-radius: 5px;
  max-width: 24em;
  flex-direction: column;
  bottom: 1em !important;
  right: 1em;
  left: auto !important;
  top: auto;
}
.cookieConsent a:hover {
  color: #fff;
}
.cokielist {
  list-style-type: disc;
  padding-left: 17px;
  margin-bottom: 30px;
}
.cokielist li {
  padding: 5px 0;
}
.cookies-btn {
}
.cc-btn {
  display: inline-block;
  flex: 1 1;
  border-radius: 5px !important;
  line-height: 1.5em !important;
  padding: 0.6em 0.8em !important;
  font-size: 1em !important;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  margin: 0 auto !important;
}
.cc-btn:last-child {
  min-width: 140px;
}
.cookieConsent div {
  flex: 1 1 !important;
}

@media only screen and (max-width: 768px) {
  .cookieConsent {
    right: auto !important;
  }
  .companyname {
    float: left;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 15px;
  }
  .p-4test {
    padding: 1.5rem !important;
  }
  .block-date {
    display: block;
  }
  .mb-3 {
    margin-bottom: 3rem !important;
  }
  .floatHide {
    display: none;
  }
  .floatRight {
    margin-bottom: 5px;
  }
  .banner-container {
    top: 5%; 
    left: 5%; 
    background-color: rgba(255,255,255, 0.8); 
    background-repeat: no-repeat; 
    width: 90%; 
    padding: 10px;
    height: auto; 
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); 
  }
  .heading-text-bold {
    font-size: 13px;
  }
  .price-text-bold {
    font-size: 13px;
    color: rgb(0, 32, 96);
  }
  .para-text {
    font-size: 11px;
    margin-bottom: 10px;
  }
  .margin-top-banner {
    margin-top: 0.2rem;
  }
  .margin-bottom-banner {
    margin-bottom: 0rem;
  }
  .banner-icon {
    width: 30px;
  }
  .banner-btn {
    font-size: 10px;
  }
  .small-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 8px;
  }
  .limited-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 7px;
  }
}
@media only screen and (min-width: 768px) and (max-device-width: 1199px) {
  .margin-bottom-banner {
    margin-bottom: 0rem;
  }
  .para-text {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .subscription-demo-left {
    display: none;
  }
}
@media (max-width: 1199px) {
  .subscribe-list {
    display: none;
  }
}
@media (min-width: 1200px) {
  .subscription-demo-right {
    display: none;
  }
}
@media only screen and (min-width: 375px) and (max-device-width: 520px) {
  .banner-container {
    background-color: rgba(255,255,255, 0.8); 
    background-repeat: no-repeat; 
    width: 90%; 
    padding: 8px;
    height: auto; 
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    line-height: 1;
  }
  .heading-text-bold {
    font-size: 11px;
  }
  .price-text-bold {
    font-size: 11px;
    color: rgb(0, 32, 96);
  }
  .para-text {
    font-size: 8px;
    margin-bottom: 10px;
  }
  .banner-btn {
    font-size: 8px;
  }
  .limited-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 5px;
  }
  .small-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 6px;
  }
  .banner-border-bottom {
    border-bottom: 2px solid rgba(26,182,223,0.1);
  }
  .subscription-demo-left {
    display: none;
  }
}
@media only screen and (min-width: 521px) and (max-device-width: 768px) {
  .banner-container {
    background-color: rgba(255,255,255, 0.8); 
    background-repeat: no-repeat; 
    width: 90%; 
    padding: 15px;
    height: auto; 
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    line-height: 1.5;
  }
  .heading-text-bold {
    font-size: 12px;
  }
  .price-text-bold {
    font-size: 13px;
    color: rgb(0, 32, 96);
  }
  .para-text {
    font-size: 10px;
    margin-bottom: 10px;
  }
  .banner-btn {
    font-size: 8px;
  }
  .limited-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 5px;
  }
  .small-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 6px;
  }
  .banner-border-bottom {
    border-bottom: 2px solid rgba(26,182,223,0.1);
  }
  .subscription-demo-left {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .banner-container {
    background-color: rgba(255,255,255, 0.8); 
    background-repeat: no-repeat; 
    width: 90%; 
    padding: 20px;
    height: auto; 
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
  .banner-border-bottom {
    border-bottom: 4px solid rgba(26,182,223,0.1);
  }
  .heading-text-bold {
    font-size: 18px;
  }
  .price-text-bold {
    font-size: 18px;
    color: rgb(0, 32, 96);
  }
  .margin-top-banner {
    margin-top: 1rem;
  }
  .banner-icon {
    width: 40px;
  }
  .small-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 12px;
  }
  .limited-text-gray {
    color: rgba(20,20,20,.73);
    font-size: 10px;
  }
}

.col-md-10 {
  display: none;
}
/*# sourceMappingURL=App.css.map */
.col-auto {
  display: block;
}

.purchase-subscription-button {
  background: rgb(26 82 156);
  color: white;
  width: 40%;
}
.purchase-subscription-button:hover {
  background: rgb(26 82 156);
  color: white;
  width: 40%;
}

.StripeElement {
  border: 1px solid #b7b7b7;
  padding: 12px;
  margin-bottom: 16px;
  width: 100%;
}
/* mixin for multiline */
.brandlogo {
  padding: 30px 0 34px 0;
  text-align: center;
}

.brandlogo .am-logo {
  height: auto;
  max-width: 100%;
  width: auto;
}

.date-range {
  margin: 0 !important;
}
/*# sourceMappingURL=logo.css.map */
/* mixin for multiline */
footer {
  border-top: 1px solid #d0cfcf;
  margin-top: 50px;
}

footer .footer-top-holder {
  padding: 35px 0 50px 0;
}

@media only screen and (max-width: 767px) {
  footer .footer-top-holder {
    text-align: center;
  }
}

@media only screen and (max-width: 990px) {
  footer .footer-top-holder {
    text-align: center;
  }
}

footer .footer-top-holder .brandlogo-footer .brandlogo {
  padding: 0;
}

footer .footer-top-holder .brandlogo-footer .brandlogo img {
  width: 312px;
}

@media only screen and (max-width: 990px) {
  footer .footer-top-holder .brandlogo-footer .brandlogo {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 990px) {
  footer .footer-top-holder .menu--dsah1 {
    text-align: center !important;
  }
}

footer .footer-top-holder .menu--dsah1 ul li {
  list-style-type: none;
  display: inline;
}

footer .footer-top-holder .menu--dsah1 ul li:not(:first-child):before {
  content: " | ";
  color: black;
  margin: 0 5px;
}

footer .footer-top-holder .menu--dsah1 a {
  display: inline-block;
  color: #000;
  font-family: "Roboto";
}

footer .footer-bottom-holder {
  background: #1a529c;
  padding: 19px 0;
  font-family: "Roboto";
  text-align: center;
}

.menu--dsah {
  display: none !important;
}
/*# sourceMappingURL=footer.css.map */
/* mixin for multiline */
.fn-nav:nth-child(2) ul {
  border-bottom: 4px solid rgba(26, 182, 223, 0.1);
}

.fn-nav ul {
  list-style-type: none;
  list-style-type: none;
  display: flex;
  justify-content: center;
  border-top: 4px solid rgba(26, 182, 223, 0.1);
  align-items: center;
}

.fn-nav li {
  margin: 0 10px;
  position: relative;
}

.fn-nav li a {
  color: #141414;
  font-family: "Roboto";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 3px;
  display: block;
  text-align: center;
}

.fn-nav li a:hover {
  background: rgba(26, 182, 223, 0.4);
}

.fn-nav li a.active {
  background: rgba(26, 182, 223, 0.4);
}

.fn-nav li:not(:first-child):before {
  content: " | ";
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: -13px;
  align-items: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.sector-mobile select {
  border-radius: 0;
  margin: 5px 0;
}

@media only screen and (max-width: 479px) {
  #sector-desktops {
    display: none !important;
  }
}
/*# sourceMappingURL=navfilter.css.map */
/* mixin for multiline */
.news-module {
  margin-bottom: 30px;
}

.news-module .news-content {
  border: 1px solid #eee;
  padding: 10px 0;
}

.news-module .news-content small {
  font-family: "Roboto";
  color: rgba(20, 20, 20, 0.73);
}

.news-module .news-content a {
  color: #010101;
  line-height: 26px;
}

@media only screen and (min-width: 768px) {
  .news-module .news-content h3 {
    height: 108px;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 767px) {
  .news-module .news-content h3 {
    height: 130px;
  }
}

.news-module .news-image {
  position: relative;
  height: 158px;
}

@media only screen and (max-width: 767px) {
  .news-module .news-image {
    height: auto;
  }
}

.news-module .news-image img {
  width: 100%;
}

.news-module .news-image span.timeupdated {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  width: 100%;
  padding: 5px 12px;
  font-size: 0.6875rem;
}

.news-module .news-image span.timeupdated img {
  width: 19px;
  margin-right: 8px;
}

audio.react-audio-player {
  width: 100%;
  height: 36px;
  display: block;
  background: #f1f3f4;
  opacity: 0.9;
}

audio.react-audio-player:focus {
  outline: 0;
}

audio.react-audio-player p {
  margin: 0;
  padding: 0;
}
/*# sourceMappingURL=news-post.css.map */
/* mixin for multiline */
.fsubs {
  text-align: center;
  padding: 20px;
  font-family: "Roboto";
  font-size: 1.0625rem;
}

.fsubs p strong {
  font-size: 1.125rem;
}

.fsubs form {
  width: 100%;
  margin: 0 auto;
}

.fsubs form .group {
  position: relative;
  margin: 25px 0 20px 0;
}

.fsubs form .group input {
  background: none;
  color: #b3b3b3;
  padding: 10px 10px 10px 5px;
  text-align: center;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #b3b3b3;
}

.fsubs form .group input:focus {
  outline: none;
}

.fsubs form .group input:focus ~ label,
.fsubs form .group input:valid ~ label {
  top: -14px;
  color: #1ab6df;
}

.fsubs form .group input:focus ~ .bar:before {
  width: 100%;
}

.fsubs form .group label {
  color: #b3b3b3;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 10px;
  transition: 300ms ease all;
  font-size: 0.875rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.fsubs form .group .bar {
  position: relative;
  display: block;
  width: 100%;
}

.fsubs form .group .bar:before {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #1ab6df;
  transition: 300ms ease all;
  left: 0;
}
/*# sourceMappingURL=subscription.css.map */
/* mixin for multiline */
.invesintell nav {
  display: flex;
}

.invesintell nav.nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
  font-family: "Roboto";
  font-weight: 400;
  text-align: center;
  line-height: normal;
  flex: 1 1;
  align-items: center;
  display: -ms-grid;
  display: grid;
  color: #010101;
  font-weight: 500;
  padding: .8rem 1rem;
}

.invesintell nav.nav-tabs .nav-link.active {
  background: #1ab6df;
  color: #fff;
  font-weight: 500;
}

.invesintell .tab-content {
  padding: 10px;
}

.invesintell .tab-content ul {
  list-style-type: none;
}

.invesintell .tab-content ul li {
  margin-bottom: 10px;
}

.invesintell .tab-content ul li a {
  color: #141414;
}

.invesintell .tab-content ul small {
  display: block;
  text-align: right;
  color: rgba(20, 20, 20, 0.73);
}
/*# sourceMappingURL=investment-intelligence.css.map */
/* No CSS *//*# sourceMappingURL=investment-wire.css.map */
/* mixin for multiline */
[id*='facebook'] {
  background: #fff;
}

[id*='facebook']::before {
  content: '';
  background: url(/static/media/facebook.d009da72.svg) no-repeat center center;
}

[id*='twitter'] {
  background: #fff;
}

[id*='twitter']::before {
  content: '';
  background: url(/static/media/twitter.bd3b7138.svg) no-repeat center center;
}

[id*='linkedin'] {
  background: #fff;
}

[id*='linkedin']::before {
  content: '';
  background: url(/static/media/linkedin.42d8d3cb.svg) no-repeat center center;
}

[id*='instagram'] {
  background: #fff;
}

[id*='instagram']::before {
  content: '';
  background: url(/static/media/instagram.5e268648.svg) no-repeat center center;
}

[id*='youtube'] {
  background: #fff;
}

[id*='youtube']::before {
  content: '';
  background: url(/static/media/youtube.a4e1af2d.svg) no-repeat center center;
}

[id*='whatsapp'] {
  background: #fff;
}

[id*='whatsapp']::before {
  content: '';
  background: url(/static/media/whatsapp.8153dd6d.svg) no-repeat center center;
}

[id*='envelope'] {
  background: #fff;
}

[id*='envelope']::before {
  content: '';
  background: url(/static/media/envelope.49585051.svg) no-repeat center center;
}

[id*='print'] {
  background: #fff;
}

[id*='print']::before {
  content: '';
  background: url(/static/media/print.dae62735.svg) no-repeat center center;
}

ul.social {
  list-style-type: none;
}

ul.social.bigs button {
  text-decoration: none;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
}

ul.social.bigs button::before {
  display: inline-block;
  vertical-align: middle;
  margin: 0.5em 0.5em 0.5em 0;
  height: 25px;
  width: 25px;
}

ul.social.bigs button:focus {
  box-shadow: none;
}

ul.social.smalls button {
  text-decoration: none;
  height: 30px;
  width: 30px;
  padding: 0;
  border: 0;
}

ul.social.smalls button::before {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 100%;
}

ul.social.smalls button:hover {
  background: transparent;
}

ul.social.smalls button:focus {
  box-shadow: none;
}

ul.social li {
  display: -ms-inline-grid;
  display: inline-grid;
  margin-right: 10px;
}

ul.social li:last-child {
  margin: 0;
}

ul.social li.print-icon a:before {
  margin: 0;
  height: 30px;
  width: 30px;
}
/*# sourceMappingURL=followus.css.map */
/* mixin for multiline */
.offers-sec {
  border-radius: 0 !important;
  background: url(/static/media/foff-omg.8ddeae93.jpg) no-repeat right;
  background-size: cover;
}

.offers-sec .h5 {
  font-size: 1.21875rem;
}

.offers-sec .card-body .card-title {
  font-weight: 700 !important;
  font-family: "Roboto";
}

.offers-sec.grad-bg {
  background: url(/static/media/grad-img-bg.3e6b659d.png) no-repeat right;
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.offers-sec.grad-bg .h5 {
  font-size: 1.21875rem;
}

.widget {
  margin: 0 0 42px;
}
/*# sourceMappingURL=offers-widget.css.map */
/* mixin for multiline */
.row-1, .row-2 {
  display: flex;
  flex-wrap: wrap;
}

.row-1 .bnr2blk, .row-2 .bnr2blk {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
}

@media only screen and (min-width: 400px) {
  .row-1 .bnr2blk, .row-2 .bnr2blk {
    width: 170px;
  }
}

.row-1 .bnr2blk h6, .row-2 .bnr2blk h6 {
  font-size: 14px;
  font-weight: 600;
}

.row-1 .bnr2blk img, .row-2 .bnr2blk img {
  width: 50px;
  margin-bottom: 10px;
}

.row-1 {
  justify-content: space-around;
}

@media only screen and (min-width: 768px) {
  .row-1 {
    padding: 0px 60px;
  }
}

.row-2 {
  justify-content: space-between;
}
/*# sourceMappingURL=banner-widget.css.map */
.mt-18 {
  margin-top: 18px !important;
}

.centerAlign {
  margin-right: auto;
  margin-left: auto;
  display: table;
  border-radius: 0px !important;
}
/*# sourceMappingURL=home.css.map */
/* mixin for multiline */
.menuIconBar button {
  background: transparent;
  border: 0;
}

.menuIconBar button:focus {
  box-shadow: none;
  outline: 0;
}

.menuIconBar img {
  width: 20px;
}

.side-nav {
  width: 328px !important;
  height: auto;
  position: absolute;
  top: 61px;
  left: -61vw;
  z-index: 9999 !important;
  box-shadow: 0 0 38px 0 rgba(99, 99, 99, 0.47);
}

@media only screen and (max-width: 1023px) {
  .side-nav {
    width: 315px;
    top: 53px;
  }
}

.side-nav.opened {
  left: 0;
}

.side-nav nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  background-color: #fff;
}

.side-nav nav ul li {
  border-bottom: 1px solid #dbdbdb;
}

.side-nav nav ul li a {
  display: block;
  text-decoration: none;
  padding: 20px 0 20px 47px;
  font-size: 1.0625rem;
  font-family: "Roboto";
  color: #000;
}

.side-nav nav ul li .dropdown-content {
  display: none;
  left: 315px;
  float: left;
  position: absolute;
  margin-top: -65px;
  z-index: 1;
  box-shadow: 0 0 38px 0 rgba(99, 99, 99, 0.47);
}

@media only screen and (max-width: 639px) {
  .side-nav nav ul li .dropdown-content {
    left: 25vw;
  }
}

.side-nav nav ul li .dropdown-content li {
  border-bottom: 1px solid #dbdbdb;
}

.side-nav nav ul li .dropdown-content a {
  background-color: #fff;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 20px 0 20px 45px !important;
  font-size: 1rem;
}

.side-nav nav ul li .dropdown-content a:after {
  content: none !important;
}

.side-nav nav ul li:hover > a {
  color: #fff;
  background: #1ab6df;
}

.side-nav nav ul li.dropdown:hover > .dropdown-content {
  display: inline-block;
}

.side-nav nav ul li.dropdown a {
  position: relative;
}

.side-nav nav ul li.dropdown a:after {
  position: absolute;
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAARCAYAAAAPFIbmAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkNGMzRDMzExRjU4RDExRTk5QkE3QUIwNDk3MTcxOTA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkNGMzRDMzEyRjU4RDExRTk5QkE3QUIwNDk3MTcxOTA3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Q0YzNEMzMEZGNThEMTFFOTlCQTdBQjA0OTcxNzE5MDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Q0YzNEMzMTBGNThEMTFFOTlCQTdBQjA0OTcxNzE5MDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5NvLa+AAAA1UlEQVR42nzSywpBQRzH8ek4G48gWRAlueSysLGysbOzUhYewqMg5QG8ikvKWkqKiLBwySXfqb86jXP861Nn6tec+c9/lFKqhbD6Uxb8aCDgFfJhjhwKmOHqFjpjhTTymOJuhnQdsEUKGUzwNEO6djhJMIkx3mZI1wY3CcUxdAspOd9Ldoxg4fPoeokYQvpslkeohKCER7ZLoIiydNv/3rizsqjgiJ5zLN9KoIoLuniYoShqcoEdczSWdFCXdVvG9PMKmvKtf7F3a9WWrQdYez2VjwADAHsJKwaEoA+pAAAAAElFTkSuQmCC);
  right: 45px;
}

.side-nav nav ul li.menuinit .dropdown-content li {
  border-bottom: 1px solid #dbdbdb;
}

.side-nav nav ul li.menuinit a.no-bg {
  background: transparent;
  color: #000;
}

.side-nav nav ul li.menuinit a.no-bg:hover {
  background: #1ab6df;
  color: #fff;
}

.side-nav nav ul li.menuinit li {
  border: 0;
}

.side-nav nav ul li.menuinit li.dropdown a {
  padding-left: 100px;
}

.side-nav-toggler {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.side-nav-toggler span {
  position: relative;
  display: block;
  width: 1.5rem;
  height: 0.3rem;
  background: #fff;
  cursor: pointer;
  margin: 0.2rem 0;
  top: 3px;
}

.side-nav-toggler:after {
  content: " ";
  position: absolute;
  width: 4rem;
  height: 4rem;
  background: rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  margin: -2rem;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}
/*# sourceMappingURL=topnav.css.map */
/* mixin for multiline */
@media only screen and (max-width: 767px) {
  .lang {
    display: flex;
  }
}

.lang a {
  color: #fff;
  opacity: 0.9;
  font-weight: 700;
  font-size: 1.0625rem;
}

@media only screen and (max-width: 767px) {
  .lang a {
    word-break: keep-all;
  }
}

.lang a:hover {
  text-decoration: none;
  color: #fff;
  opacity: 1;
}

.lang span {
  margin: 0 10px;
  color: #fff;
  opacity: 0.7;
}
/*# sourceMappingURL=language.css.map */
/* mixin for multiline */
.nav-search {
  margin-left: 25px !important;
}

.nav-search .input-group-text {
  background-color: #fff;
  border: 0;
}

.nav-search .form-control {
  background-color: #fff;
  border: 0;
  padding-left: 0;
  width: 500px;
  border-radius: 0.25rem;
  padding: 0.6rem 0.75rem !important;
}

@media only screen and (max-width: 767px) {
  .nav-search #topSerach {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .nav-search #topSerach {
    max-width: -webkit-fit-content;
    max-width: fit-content;
  }
}

@media only screen and (min-width: 991px) {
  .nav-search #topSerach {
    width: 323px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-search .input-group-text {
    border-radius: 0.25rem !important;
  }
}

.nav-search .src-mobile {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 767px) {
  .nav-search .src-mobile {
    position: absolute;
    left: 50%;
    top: 53px;
    width: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .nav-search .src-mobile #topSerach {
    display: block;
    height: 72px;
    width: 100%;
    border-radius: 0;
  }
}

@media only screen and (min-width: 768px) {
  .nav-search .src-mobile #topSerach {
    display: none;
  }
}

.nav-search .src-mobile a.close-sec {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
  text-decoration: none;
  color: rgba(20, 20, 20, 0.7);
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .nav-search .src-mobile a.close-sec {
    display: none;
  }
}

.input-group {
  visibility: visible;
}
/*# sourceMappingURL=search.css.map */
/* mixin for multiline */
.signin {
  color: #fff;
  opacity: 0.9;
  margin: 0 20px;
}

.signin:hover {
  opacity: 1;
  color: #fff;
}

.fa-bell {
  font-size: 30px;
  vertical-align: middle;
}

@media only screen and (max-width: 600px) {
  .extraclass {
    padding-left: 5px !important;
    max-width: 60px;
    margin-left: 4px;
  }
}

.lang {
  display: none !important;
}

@media only screen and (max-width: 479px) {
  .lang {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .btn {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 320px) {
  .btn {
    font-size: 8px !important;
  }
}

@media only screen and (max-width: 479px) {
  .fa-bell {
    display: none;
  }
}

.userbutton {
  font-size: 0.8rem !important;
  padding: 0.275rem !important;
}

.extraclass {
  display: none;
}

@media only screen and (max-width: 812px) {
  .margB {
    margin-bottom: 0.7rem !important;
  }
}
/*# sourceMappingURL=usernav.css.map */
/* mixin for multiline */
header .brandlogo img {
  width: 510px !important;
}

@media only screen and (max-width: 767px) {
  header .brandlogo img {
    padding: 0 10%;
  }
}

header .top--bar {
  background: #1a529c;
  color: #fff;
  padding: 10px 0;
  height: 60px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  header .top--bar {
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  header .top--bar {
    height: auto;
  }
}

header .top--bar .navbar-nav li.nav-item {
  margin-left: 33px;
}

@media only screen and (max-width: 479px) {
  header .top--bar .navbar-nav li.nav-item {
    margin-left: 10px;
  }
}
/*# sourceMappingURL=header.css.map */
.details {
  color: #1a529c;
  font-size: 20px;
  line-height: 5px;
  font-weight: 600;
}

.alignLeft {
  align-items: center;
  display: flex;
  padding-bottom: 15px;
  font-size: 17px;
}

.fsubs1 {
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: "Roboto";
  font-size: 1.0625rem;
}

.borderhr {
  border-top: 1px dotted rgba(0, 0, 0, 0.6) !important;
}

.container-sub {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fontWeight {
  font-weight: 600;
}

.mt-18 {
  margin-top: 18px;
}

.font-14 {
  color: rgba(20, 20, 20, 0.73);
  font-size: 12px;
}

.border-bottom-data {
  border-bottom: 2px solid #dee2e6 !important;
}

.font-14l {
  color: rgba(20, 20, 20, 0.73);
  font-size: 12px;
  line-height: 1px;
}

.font-19 {
  font-size: 19px;
  padding-bottom: 10px;
}

.fonts-14 {
  color: rgba(20, 20, 20, 0.73);
  font-size: 12px;
}

.font14 {
  color: rgba(20, 20, 20, 0.73);
  font-size: 12px;
}

@media only screen and (min-width: 1200px) {
  .font14 {
    color: rgba(20, 20, 20, 0.73);
    font-size: 12px;
  }
  .fonts-14 {
    color: rgba(20, 20, 20, 0.73);
    font-size: 12px;
    line-height: 1px;
  }
}

@media only screen and (max-width: 1024px) {
  .font14 {
    color: rgba(20, 20, 20, 0.73);
  }
  .fonts-14 {
    color: rgba(20, 20, 20, 0.73);
  }
}

@media only screen and (max-width: 600px) {
  .font16mob {
    font-size: 16px;
    font-weight: 500;
  }
  .mbmob-3 {
    margin-bottom: 1px !important;
  }
}
/*# sourceMappingURL=weekly-info.css.map */
/* mixin for multiline */
.registration-form-container {
  border: 1px solid #cecece;
  padding: 50px 100px;
}

@media only screen and (max-width: 767px) {
  .registration-form-container {
    padding: 20px;
  }
}

.registration-form-container .registration-form h2 {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}

.registration-form-container .registration-form h3 {
  font-size: 18px;
  color: #141414;
  font-weight: 500;
  line-height: 28px;
}

.animationMode {
  -webkit-animation: fadeIn 1s ease-in-out 3s;
          animation: fadeIn 1s ease-in-out 3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.error-message {
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  display: table;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px;
}

.error-text {
  color: #cc0033;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  text-shadow: 1px 1px rgba(250, 250, 250, 0.3);
}
/*# sourceMappingURL=signin.css.map */
/* mixin for multiline */
.fn-nav:nth-child(2) ul {
  border-bottom: 4px solid rgba(26, 182, 223, 0.1);
}

.fn-nav ul {
  list-style-type: none;
  list-style-type: none;
  display: flex;
  justify-content: center;
  border-top: 4px solid rgba(26, 182, 223, 0.1);
  align-items: center;
}

.fn-nav li {
  margin: 0 10px;
  position: relative;
}

.fn-nav li a {
  color: #141414;
  font-family: "Roboto";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 3px;
  display: block;
  text-align: center;
}

.fn-nav li a:hover {
  background: rgba(26, 182, 223, 0.4);
}

.fn-nav li a.active {
  background: rgba(26, 182, 223, 0.4);
}

.fn-nav li:not(:first-child):before {
  content: " | ";
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: -13px;
  align-items: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.sector-mobile select {
  border-radius: 0;
  margin: 5px 0;
}

/* mixin for multiline */
.fn-nav:nth-child(2) ul {
  border-bottom: 4px solid rgba(26, 182, 223, 0.1);
}

.fn-nav ul {
  list-style-type: none;
  list-style-type: none;
  display: flex;
  justify-content: center;
  border-top: 4px solid rgba(26, 182, 223, 0.1);
  align-items: center;
}

.fn-nav li {
  margin: 0 10px;
  position: relative;
}

.fn-nav li a {
  color: #141414;
  font-family: "Roboto";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  padding: 7px 10px;
  border-radius: 3px;
  display: block;
  text-align: center;
}

.fn-nav li a:hover {
  background: rgba(26, 182, 223, 0.4);
}

.fn-nav li a.active {
  background: rgba(26, 182, 223, 0.4);
}

.fn-nav li:not(:first-child):before {
  content: " | ";
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: -13px;
  align-items: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sector-mobile select {
  border-radius: 0;
  margin: 5px 0;
}

.uppercase {
  font-size: 11px;
}

.fa-home {
  font-size: 24px;
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0 0;
  flex-wrap: nowrap;
  border-top: 4px solid rgba(26, 182, 223, 0.1);
}

.navbar1 {
  border-bottom: 4px solid rgba(26, 182, 223, 0.1);
}

.navbar__link {
  padding: 7px 10px;
  font-size: 11px;
  font-family: "Roboto";
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #181818;
  font-weight: 500;
  /* border-bottom: 3px solid transparent; */
  width: auto;
  text-align: center;
}

.navbar__link:hover {
  background-color: rgba(26, 182, 223, 0.4);
  transition: background-color 0.5s ease-in-out;
  text-decoration: none;
}

.navbar__link--active {
  background-color: rgba(26, 182, 223, 0.4);
  transition: background-color 0.5s ease-in-out;
}

.pl-42 {
  padding-left: 42.3px;
}
/*# sourceMappingURL=navfilterafterLogin.css.map */
.bordersize {
  border: 2px solid #dbdada;
  padding: 15px;
}
/*# sourceMappingURL=homeafterLogin.css.map */
/* mixin for multiline */
.menuIconBar button {
  background: transparent;
  border: 0;
}

.menuIconBar button:focus {
  box-shadow: none;
  outline: 0;
}

.menuIconBar img {
  width: 20px;
}

.side-nav {
  width: 315px;
  height: auto;
  position: absolute;
  top: 61px;
  left: -61vw;
  z-index: 9999;
  box-shadow: 0 0 38px 0 rgba(99, 99, 99, 0.47);
}

@media only screen and (max-width: 1023px) {
  .side-nav {
    width: 315px;
    top: 53px;
  }
}

.side-nav.opened {
  left: 0;
}

.side-nav nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  background-color: #fff;
}

.side-nav nav ul li {
  border-bottom: 1px solid #dbdbdb;
}

.side-nav nav ul li a {
  display: block;
  text-decoration: none;
  padding: 20px 0 20px 47px;
  font-size: 1.0625rem;
  font-family: "Roboto";
  color: #000;
}

.side-nav nav ul li .dropdown-content {
  display: none;
  left: 315px;
  float: left;
  position: absolute;
  margin-top: -65px;
  z-index: 1;
  box-shadow: 0 0 38px 0 rgba(99, 99, 99, 0.47);
}

@media only screen and (max-width: 639px) {
  .side-nav nav ul li .dropdown-content {
    left: 25vw;
  }
}

.side-nav nav ul li .dropdown-content li {
  border-bottom: 1px solid #dbdbdb;
}

.side-nav nav ul li .dropdown-content a {
  background-color: #fff;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 20px 0 20px 45px !important;
  font-size: 1rem;
}

.side-nav nav ul li .dropdown-content a:after {
  content: none !important;
}

.side-nav nav ul li:hover > a {
  color: #fff;
  background: #1ab6df;
}

.side-nav nav ul li.dropdown:hover > .dropdown-content {
  display: inline-block;
}

.side-nav nav ul li.dropdown a {
  position: relative;
}

.side-nav nav ul li.dropdown a:after {
  position: absolute;
  content: url("");
  right: 45px;
}

.side-nav nav ul li.menuinit .dropdown-content li {
  border-bottom: 1px solid #dbdbdb;
}

.side-nav nav ul li.menuinit a.no-bg {
  background: transparent;
  color: #000;
}

.side-nav nav ul li.menuinit a.no-bg:hover {
  background: #1ab6df;
  color: #fff;
}

.side-nav nav ul li.menuinit li {
  border: 0;
}

.side-nav nav ul li.menuinit li.dropdown a {
  padding-left: 100px;
}

.side-nav-toggler {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.side-nav-toggler span {
  position: relative;
  display: block;
  width: 1.5rem;
  height: 0.3rem;
  background: #fff;
  cursor: pointer;
  margin: 0.2rem 0;
  top: 3px;
}

.side-nav-toggler:after {
  content: " ";
  position: absolute;
  width: 4rem;
  height: 4rem;
  background: rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  margin: -2rem;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.uppercase1 a {
  font-family: Roboto !important;
}

.side-nav {
  width: auto;
}
/*# sourceMappingURL=topnavafterlogin.css.map */
/* mixin for multiline */
.nav-search {
  margin-left: 25px !important;
}

.nav-search .input-group-text {
  background-color: #fff;
  border: 0;
}

.nav-search .form-control {
  background-color: #fff;
  border: 0;
  padding-left: 0;
  width: 500px;
  border-radius: 0.25rem;
  padding: 0.6rem 0.75rem !important;
}

@media only screen and (max-width: 767px) {
  .nav-search #topSerach {
    display: none;
  }
}

@media only screen and (min-width: 991px) {
  .nav-search #topSerach {
    width: 323px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-search .input-group-text {
    border-radius: 0.25rem !important;
  }
}

.nav-search .src-mobile {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 767px) {
  .nav-search .src-mobile {
    position: absolute;
    left: 50%;
    top: 53px;
    width: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .nav-search .src-mobile #topSerach {
    display: block;
    height: 72px;
    width: 100%;
    border-radius: 0;
  }
}

@media only screen and (min-width: 768px) {
  .nav-search .src-mobile #topSerach {
    display: none;
  }
}

.nav-search .src-mobile a.close-sec {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
  text-decoration: none;
  color: rgba(20, 20, 20, 0.7);
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .nav-search .src-mobile a.close-sec {
    display: none;
  }
}

.input-group1 {
  visibility: hidden;
}
/*# sourceMappingURL=searchlogin.css.map */
/* mixin for multiline */
.iis h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.iis .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.iis .c-dropdown {
  height: 125px;
  overflow-y: auto;
}
/*# sourceMappingURL=homeSidebar.css.map */
/* mixin for multiline */
.blew-box {
  background: #c6ecf6;
  padding: 15px;
}

.blew-box .playstoer-btn img {
  height: 26px;
  margin-right: 10px;
}

.blew-box p {
  color: #000;
}

.playstoer-btn {
  padding: 15px;
}

@media screen and (min-width: 1000px) {
  .col-wx {
    margin-left: -30px;
  }
}

@media screen and (max-width: 600px) {
  .wct120 {
    width: 110px;
  }
}
/*# sourceMappingURL=downloadapp.css.map */
/* No CSS *//*# sourceMappingURL=subscribers-access.css.map */
/* mixin for multiline */
.tblnoline {
  border: 1px solid #b3b3b3;
  padding: 10px;
  font-family: "Roboto";
}

.tblnoline td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.tblnoline tr:nth-child(odd) {
  background: rgba(26, 182, 223, 0.2);
}

.newstagd {
  font-size: 0.9375rem;
  color: rgba(20, 20, 20, 0.73);
}

.cathead {
  font-size: 1.25rem;
  font-family: "Roboto";
}

.floatRight {
  float: right;
}

.unorder li {
  list-style-type: disc;
}

.font18 p {
  font-size: 1.125rem;
}

.wdt100p {
  flex: 0 0 100%;
  max-width: 100%;
}

.widget {
  margin: 0 0 42px;
}

.word-wrap {
  word-break: break-word;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .w786 {
    text-align: justify;
    margin-bottom: 0.6rem !important;
  }
}
/*# sourceMappingURL=news-details.css.map */
.greyborder-company {
  border-bottom: 2px solid #dbdada;
  padding: 5px;
}

.company-name {
  font-size: 14px;
  line-height: 24px;
}

.img-logo {
  max-width: 70px;
}

.clogo-t {
  width: 186px;
}

.pad {
  padding-top: 10px;
}

.p-3test {
  padding-top: 4px;
}

.border-all {
  border: 1px solid black;
}

.breakword {
  word-break: break-all;
  color: black;
}

.tblnoline td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.tblnoline tr:nth-child(odd) {
  background: rgba(26, 182, 223, 0.2);
}

.setmargins {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.underline {
  text-decoration: none;
}

.title {
  font-size: 14px;
}

.title-wire {
  font-size: 16px;
}

.news-country {
  line-height: 33px;
}

.invest-wire {
  padding-bottom: 9px;
  font-size: 1.475rem;
  color: #42b6df;
}

.invest-intell {
  font-size: 1.475rem;
  color: #42b6df;
}

.date {
  line-height: 29px;
  font-size: 11px;
}

.placeholder {
  min-height: 106px;
  padding-left: 30px;
  padding-top: 11px;
}

.space {
  padding-right: 4px;
}

@media (max-width: 600px) {
  .company-title {
    font-size: 1.375rem;
    padding-top: 20px;
  }
  .title-wire {
    font-size: 14px;
  }
  .p-3test {
    padding-top: 0px;
  }
  .invest-wire {
    padding-bottom: 9px;
    font-size: 1.245rem;
    color: #42b6df;
  }
  .invest-intell {
    font-size: 1.245rem;
    color: #42b6df;
  }
}
/*# sourceMappingURL=company.css.map */
/* mixin for multiline */
.irs h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.irs .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.backColor {
  background-color: white !important;
}
/*# sourceMappingURL=investment-wire-screener.css.map */
/* mixin for multiline */
[id*='facebook'] {
  background: #fff;
}

[id*='facebook']::before {
  content: '';
  background: url(/static/media/facebook.d009da72.svg) no-repeat center center;
}

[id*='twitter'] {
  background: #fff;
}

[id*='twitter']::before {
  content: '';
  background: url(/static/media/twitter.bd3b7138.svg) no-repeat center center;
}

[id*='linkedin'] {
  background: #fff;
}

[id*='linkedin']::before {
  content: '';
  background: url(/static/media/linkedin.42d8d3cb.svg) no-repeat center center;
}

[id*='instagram'] {
  background: #fff;
}

[id*='instagram']::before {
  content: '';
  background: url(/static/media/instagram.5e268648.svg) no-repeat center center;
}

[id*='youtube'] {
  background: #fff;
}

[id*='youtube']::before {
  content: '';
  background: url(/static/media/youtube.a4e1af2d.svg) no-repeat center center;
}

[id*='whatsapp'] {
  background: #fff;
}

[id*='whatsapp']::before {
  content: '';
  background: url(/static/media/whatsapp.8153dd6d.svg) no-repeat center center;
}

[id*='envelope'] {
  background: #fff;
}

[id*='envelope']::before {
  content: '';
  background: url(/static/media/envelope.49585051.svg) no-repeat center center;
}

[id*='print'] {
  background: #fff;
}

[id*='print']::before {
  content: '';
  background: url(/static/media/print.dae62735.svg) no-repeat center center;
}

ul.social {
  list-style-type: none;
}

ul.social.bigs button {
  text-decoration: none;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
}

ul.social.bigs button::before {
  display: inline-block;
  vertical-align: middle;
  margin: 0.5em 0.5em 0.5em 0;
  height: 25px;
  width: 25px;
}

ul.social.bigs button:focus {
  box-shadow: none;
}

ul.social.smalls button {
  text-decoration: none;
  height: 30px;
  width: 30px;
  padding: 0;
  border: 0;
}

ul.social.smalls button::before {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 100%;
}

ul.social.smalls button:hover {
  background: transparent;
}

ul.social.smalls button:focus {
  box-shadow: none;
}

ul.social li {
  display: -ms-inline-grid;
  display: inline-grid;
  margin-right: 10px;
}

ul.social li:last-child {
  margin: 0;
}

ul.social li.print-icon a:before {
  margin: 0;
  height: 30px;
  width: 30px;
}
/*# sourceMappingURL=followus.css.map */
/* mixin for multiline */
.iis h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.iis .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.iis .c-dropdown {
  height: 125px;
  overflow-y: auto;
}

.widthEffect {
  display: block;
  width: 300px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media only screen and (max-width: 479px) {
  .mobileDevice {
    display: block;
    content: "";
    clear: both;
    overflow: auto;
    width: 300px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .mobileDevice {
    padding-bottom: 20px;
  }
  .input-group {
    display: block;
  }
}

.input-group {
  visibility: visible;
}
/*# sourceMappingURL=investment-Intelligence-screener.css.map */
/* mixin for multiline */
.imggrp {
  width: 77px;
}

.textAlign {
  text-align: center;
}

.imggrp1 {
  width: 45px;
}

.clogo-s {
  width: 107px;
  height: auto;
  margin-bottom: 5px;
}

.company-name {
  font-size: 14px;
  line-height: 24px;
}

.underline {
  text-decoration: none;
  color: #1f419b;
}

.title {
  font-size: 15px;
}

.date {
  line-height: 29px;
  font-size: 11px;
}

.placeholder {
  min-height: 106px;
  padding-left: 30px;
  padding-top: 11px;
}

.clogo-b {
  width: 78px;
  height: 36px;
  margin-bottom: 5px;
}

.tblall th {
  border-top: 0;
}

.products-view__options {
  float: right;
}

@media only screen and (min-width: 620px) {
  .clogo-s {
    width: 107px;
    height: auto;
    margin-bottom: 5px;
  }
  .p-4test {
    padding: 1.5rem !important;
  }
}

@media only screen and (min-width: 320px) {
  .clogo-s {
    float: right;
    margin-right: 5px;
  }
  .p-4test {
    padding: 1.5rem !important;
  }
}

@media only screen and (min-width: 1084px) {
  .p-4test {
    padding: 0 !important;
  }
}

.adapt {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.newstags {
  font-size: 0.9375rem;
  color: rgba(26, 82, 156, 0.73);
  font-weight: 700;
}
/*# sourceMappingURL=press-release.css.map */
/* No CSS *//*# sourceMappingURL=global-investment-intelligence.css.map */
/* No CSS *//*# sourceMappingURL=audio-player.css.map */
.btn-group a img {
  height: 32px;
  margin-left: 5px;
}
/*# sourceMappingURL=podcast-btn.css.map */
/* mixin for multiline */
.pagination a {
  text-decoration: none;
}

.pagination li.active a {
  background-color: #1ab6df !important;
  border-color: #1ab6df !important;
  text-decoration: none;
}

.products-view__options {
  display: -ms-flexbox;
  float: right;
  display: flex;
  align-items: center;
}

.products-view__options .view-options__control {
  display: flex;
  align-items: center;
}

.products-view__options .view-options__control label {
  margin-right: 10px;
}

.products-view__options .view-options__control .form-control-sm {
  padding: .25rem .5rem !important;
}

.two-item {
  display: flex;
  box-sizing: border-box;
}

.two-item .two-content-holder {
  width: 100%;
}

@media only screen and (max-width: 990px) {
  .two-item {
    flex-wrap: wrap;
  }
}

.two-item .two-image-holder {
  margin-right: 30px;
}

@media only screen and (max-width: 990px) {
  .two-item .two-image-holder {
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
  }
}

.two-item .two-image-holder img {
  width: 420px;
}

@media only screen and (max-width: 990px) {
  .two-item .two-image-holder img {
    width: 100%;
  }
}

@media only screen and (max-width: 990px) {
  .two-item .two-image-holder .image-g {
    width: 100%;
  }
}
/*# sourceMappingURL=podcasts.css.map */
/* No CSS *//*# sourceMappingURL=thumb-img.css.map */
/* mixin for multiline */
.subsbription_section_alt {
  float: none;
  display: block;
  max-width: 65%;
  width: 100%;
  margin-left: auto;
}

.subsbription_section_alt .newsletter-form {
  text-align: center;
}

.subsbription_section_alt .newsletter-form input[type="email"] {
  outline: none;
  font-family: inherit;
  color: #c6c6c8;
  font-family: inherit;
  margin: 0;
  width: 70%;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-appearance: none;
  height: auto;
  border-width: 1px 0 1px 1px;
  border-color: #b7b7b7;
  border-radius: 0;
}

.subsbription_section_alt .newsletter-form p a {
  text-decoration: underline;
}
/*# sourceMappingURL=newsletter-subscription.css.map */
/* mixin for multiline */
.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #b7b7b7;
}

.styled-checkbox:hover + label:before {
  background: #1ab6df;
}

.styled-checkbox:focus + label:before {
  box-shadow: none;
}

.styled-checkbox:checked + label:before {
  background: #1ab6df;
}

.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

@media only screen and (max-width: 479px) {
  .subsbription_section_alt {
    max-width: none;
  }
  .pt-5 {
    padding-top: 2rem !important;
  }
  .pts-2 {
    padding-top: 2rem;
  }
}
/*# sourceMappingURL=newsletters.css.map */
/* mixin for multiline */
.reachus {
  display: flex;
  flex-direction: column;
  height: 85%;
  list-style-type: none;
}

.reachus .phoneno {
  margin-bottom: 50px;
}

.reachus .phoneno span {
  display: inline-block;
}

@media only screen and (max-width: 1247px) {
  .reachus .phoneno span {
    width: 100%;
    margin-bottom: 10px;
  }
}

.reachus .phoneno span strong {
  display: inline-block;
  width: 180px;
}

@media only screen and (max-width: 1247px) {
  .reachus .phoneno span strong {
    display: block;
  }
}

.reachus li {
  font-size: 20px;
  margin-bottom: 30px;
}

.reachus li strong {
  display: block;
  font-weight: 600;
}

.reachus li span {
  display: block;
  margin: 5px 0;
}

.reachus li span img {
  width: 20px;
  margin-right: 5px;
}

.reachus li a {
  font-size: 19px;
}

@media only screen and (max-width: 990px) {
  .reachus li {
    margin-bottom: 20px;
  }
}

.addrss {
  text-align: center;
  padding: 40px;
  font-size: 16px;
}

.addrss li {
  margin: 20px 0;
  font-weight: 600;
}

@media only screen and (min-width: 1024px) {
  .addrss li:first-child {
    height: 75px;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
/*# sourceMappingURL=contact.css.map */
/* mixin for multiline */
.navigation {
  position: fixed;
  background-color: white;
  color: black;
}

.navigation__link {
  display: block;
  color: #000000;
  text-decoration: none;
  padding: 1em;
  font-weight: 400;
  border: 1px solid #b3b3b3;
}

.navigation__link.active {
  color: white;
  background-color: #2fb6df;
}

.navigation__link .monitor_color {
  color: black;
}

.navigation__link button {
  font-size: 25px;
}

.navigation__link .tblnoline {
  border: 1px solid #b3b3b3;
  padding: 10px;
  font-family: "Roboto";
}

.navigation__link .tblnoline td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.navigation__link .tblnoline tr:nth-child(odd) {
  background: #2fb6df;
}

.navigation__link .red {
  color: red;
}

.navigation__link .underline {
  text-decoration: none;
  color: #2fb6df;
}

.navigation__link .x-scroller {
  overflow-x: auto;
}

.navigation__link .filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.navigation__link .paginate_button {
  border-radius: 0 !important;
}

.navigation__link .pagination li:hover {
  cursor: pointer;
}

.navigation__link .SelectSize {
  width: 100px;
}

.navigation__link .fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.navigation__link .pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.navigation__link .pagination > li > a,
.navigation__link .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.navigation__link .table-header {
  color: white;
  text-align: center;
}

.navigation__link .AlignCenter {
  text-align: center;
}

.navigation__link .AlignMiddle {
  align-items: center;
  display: flex;
}

.navigation__link .nav-pills .nav-link.active,
.navigation__link .nav-pills .show > .nav-link {
  background-color: #2fb6df;
  color: white;
}

.navigation__link .nav-pills .nav-link {
  color: black;
  border: 1px solid #dee2e6;
}

.navigation__link .centreAlign {
  text-align: center;
}

.navigation__link .head-title {
  text-align: left;
  color: #000000;
  line-height: 50px;
  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.navigation__link #close,
.navigation__link #ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}

.navigation__link #close:hover,
.navigation__link #ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}

@media only screen and (max-width: 600px) {
  .navigation__link .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}

.paddingRight {
  padding-right: 30px;
}

.UpperCase {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
  margin-left: 1rem;
}

@media only screen and (max-width: 479px) {
  ul {
    margin-left: 0rem !important;
  }
}

.space {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  color: #2fb6df;
}

.underline1 {
  text-decoration: none;
  color: #1a529c;
}

.PrevBack {
  color: #2fb6df;
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon {
  color: #2fb6df;
  border-radius: 8%;
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon:hover {
  color: #1a529c;
}

.MarginRight {
  margin-right: 26px;
}

.PrevBack:hover {
  color: #1a529c;
}

.padDown {
  margin-bottom: 30px;
}

.DateFont {
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  #mainNav {
    display: none;
  }
}
/*# sourceMappingURL=m&adealmonitor.css.map */
/* mixin for multiline */
.iis h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.iis .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.iis .c-dropdown {
  height: 125px;
  overflow-y: auto;
}
/*# sourceMappingURL=ipo-ql.css.map */
.playstoer-btnsmall img {
  height: 35px;
  margin-right: 5px;
}
/*# sourceMappingURL=app.css.map */
/* mixin for multiline */
.iis h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.iis .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.iis .c-dropdown {
  height: 125px;
  overflow-y: auto;
}

.font-16 {
  font-size: 16px;
}
/*# sourceMappingURL=M&A_Sidebar.css.map */
/* mixin for multiline */
.subscribelist {
  font-family: "Roboto";
  font-size: 16px;
  line-height: normal;
}

.subscribelist li {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.subscribelist li p {
  margin: 0;
}

.subscribelist li p span {
  color: #1f419b;
  font-weight: 500;
}

.subscribelist li img {
  margin-right: 15px;
  width: 50px;
}

.posac {
  top: 50%;
  text-align: center;
  width: 100%;
}
/*# sourceMappingURL=subscribe.css.map */
/* mixin for multiline */
.abtlist {
  font-family: "Roboto";
  font-size: 18px;
  line-height: normal;
}

.abtlist li {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.abtlist li p {
  margin: 0;
}

.abtlist li p span {
  color: #1f419b;
  font-weight: 500;
}

.abtlist li img {
  margin-right: 15px;
  width: 70px;
}

.font-22 {
  font-size: 22px;
}
/*# sourceMappingURL=about.css.map */
/* mixin for multiline */
.ecollasp {
  font-family: "Roboto";
}

.ecollasp .card {
  padding: 10px;
  border-radius: 0;
}

.ecollasp .card .card-header {
  border: 0;
  background-color: rgba(26, 182, 223, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 990px) {
  .ecollasp .card .card-header {
    flex-wrap: wrap;
  }
}

.ecollasp .card .card-header .jd-overview span.experience,
.ecollasp .card .card-header .jd-overview span.location {
  font-size: 18px;
}

.ecollasp .card .card-header .jd-overview .posteddt {
  margin-top: 15px;
}

@media only screen and (max-width: 990px) {
  .ecollasp .card .card-header .jd-overview .posteddt {
    margin: 0;
  }
}

.ecollasp .card .card-header .jobact {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 990px) {
  .ecollasp .card .card-header .jobact {
    justify-content: space-between;
    width: 100%;
  }
}

@media only screen and (max-width: 559px) {
  .ecollasp .card .card-header .jobact {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 990px) {
  .ecollasp .card .card-header .jobact {
    margin-top: 10px;
  }
}

.ecollasp .card .card-header .jobact .noopening {
  align-items: center;
  display: flex;
  font-size: 20px;
}

@media only screen and (max-width: 559px) {
  .ecollasp .card .card-header .jobact .noopening {
    margin: 0;
    padding-bottom: 15px;
  }
}

.ecollasp .card .card-header .jobact .noopening span {
  display: inline-block;
  width: 80px;
  height: 80px;
  background: #1ab6df;
  color: #fff;
  border-radius: 100px;
  text-align: center;
  line-height: 80px;
  font-size: 40px;
  font-weight: 700;
  margin-right: 10px;
}

@media only screen and (max-width: 990px) {
  .ecollasp .card .card-header .jobact .noopening span {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 1.1875rem;
  }
}

.ecollasp .card .card-body {
  padding: 25px 0 0 0;
}

.ecollasp .card .card-body .jdpoints li {
  margin-bottom: 15px;
  list-style-type: disc;
}

.ecollasp .card .card-body h3 {
  color: #434343;
  font-size: 1.625rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.jd-overview {
  display: flex;
  flex-direction: column;
}
/*# sourceMappingURL=careers.css.map */
/* mixin for multiline */
.l-sec {
  font-family: "Roboto";
}

.l-sec label {
  font-size: 1.125rem;
  font-weight: 600;
}

.l-sec ul {
  margin: 0 -10px;
}

.l-sec ul li a {
  font-size: 1.0625rem;
  color: #000;
  text-decoration: underline;
  display: block;
  padding: 10px;
}

.l-sec ul li a.active {
  background: rgba(26, 182, 223, 0.4);
}

.date-range .react-datepicker-wrapper {
  width: 100%;
}

.date-range .react-datepicker-wrapper input {
  width: 100%;
  border: 1px solid #b7b7b7;
  padding: 10px;
  margin: 5px 0;
}

.navbar__link--active2 {
  color: #1a529c !important;
  background-color: transparent;
}

.colorBlack {
  font-size: 17px;
}
/*# sourceMappingURL=screener.css.map */
/* mixin for multiline */
.iis h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.iis .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.iis .c-dropdown {
  height: 125px;
  overflow-y: auto;
}
/*# sourceMappingURL=realestate-deals-ql.css.map */
/* mixin for multiline */
.iis h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.iis .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.iis .c-dropdown {
  height: 125px;
  overflow-y: auto;
}

.navbar__link--active1 {
  color: #1a529c !important;
  background-color: transparent;
  font-weight: 600;
}
/*# sourceMappingURL=create-deal-sidebar.css.map */
.InputContainer {
  width: 3em !important;
}

.redColor {
  color: red;
}
/*# sourceMappingURL=confirmpassword.css.map */
.monitor_color {
  color: black;
}

button {
  font-size: 25px;
}

.red {
  color: red;
}

table thead tr th {
  background-color: #3e538c;

  position: relative;
}

.filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.paginate_button {
  border-radius: 0 !important;
}
.pagination li:hover {
  cursor: pointer;
}
.SelectSize {
  width: 100px;
}

.fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}
.pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.table-header {
  color: white;
}
#close,
#ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}
#close:hover,
#ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}
@media only screen and (max-width: 600px) {
  .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}

.ma-title {
  font-size: 20px;
  line-height: 50px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: #990000;
  display: table;
}
.border_bottom {
  border-bottom: 1px solid black;
}
.border_bottomstyle {
  border-bottom: 1px dashed black;
}
.subheading {
  color: #000000;

  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.subheading1 {
  display: table;
  /* margin: auto; */
  line-height: 50px;
  font-size: 18px;
}
.suburls {
  line-height: 21px;
  text-decoration: underline;
  color: black;
}
.imgsize {
  width: 800px;
}
.color {
  color: black;
  font-size: 16px;
}
.ma_sidebar_color {
  color: black;
}
.ma_sidebar_color a:active {
  color: #33cbfc;
}
.setPosition {
  position: absolute;
  bottom: 20px;
}
.uppercase {
  text-transform: uppercase;
}

.borderstyle {
  border: 2px solid #797979;
}

@media only screen and (max-width: 600px) {
  .imgsize {
    width: 358px;
  }
}

.font-16 {
  font-size: 16px;
}
td > p{
  margin: 0;
}
/*# sourceMappingURL=v&c_dealmonitor.css.map */
/* mixin for multiline */
.iis h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.iis .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.iis .c-dropdown {
  height: 125px;
  overflow-y: auto;
}

.font-16 {
  font-size: 16px;
}
/*# sourceMappingURL=vc_deals_sidebar.css.map */
.nav-link {
  border-bottom: 1px solid #dbdada;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #1a529c;
}

.navss-item {
  border-right: 2px solid #a5a5a5;
  padding-right: 10px;
}

.nav-s {
  border-top: 1px solid #dbdada;
  border-left: 1px solid #dbdada;
  border-right: 1px solid #dbdada;
}

.borderback1 {
  border-top: 15px solid #a5a5a5;
  border-bottom: 15px solid #a5a5a5;
  border-left: 2px solid #a5a5a5;
  border-right: 2px solid #a5a5a5;
  margin-left: 10px;
  margin-right: 10px;
}

.padddown {
  padding-bottom: 10px;
}

.padTopDown {
  padding-top: 20px;
}

.clogo-d {
  width: 100px;
  height: auto;
  float: right;
  margin-right: 30px;
}

.fa-file-pdf-o {
  font-size: 22px;
  color: #f34533;
}

.spaceRight {
  padding-right: 10px;
}

.borderDig {
  border-left: #dbdada;
  border-right: #dbdada;
  border-top: #dbdada;
}

.padup {
  padding-top: 20px;
}

.padDowns {
  padding-bottom: 10px;
}

.font14 {
  color: rgba(25, 25, 25, 0.8);
}

.padTopsDown {
  padding-top: 30px;
}

.flex-columns {
  flex-direction: row !important;
}

.colorBlack {
  color: #4d6fae;
}

.Minheight {
  min-height: 130px;
}
/*# sourceMappingURL=global-company.database.css.map */
/* mixin for multiline */
.navigation {
  position: fixed;
  background-color: white;
  color: black;
}

.navigation__link {
  display: block;
  color: #000000;
  text-decoration: none;
  padding: 1em;
  font-weight: 400;
  border: 1px solid #b3b3b3;
}

.navigation__link.active {
  color: white;
  background-color: #2fb6df;
}

.navigation__link .monitor_color {
  color: black;
}

.navigation__link button {
  font-size: 25px;
}

.navigation__link .tblnoline {
  border: 1px solid #b3b3b3;
  padding: 10px;
  font-family: "Roboto";
}

.navigation__link .tblnoline td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.navigation__link .tblnoline tr:nth-child(odd) {
  background: #2fb6df;
}

.navigation__link .red {
  color: red;
}

.navigation__link .underline {
  text-decoration: none;
  color: #2fb6df;
}

.navigation__link .x-scroller {
  overflow-x: auto;
}

.navigation__link .filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.navigation__link .paginate_button {
  border-radius: 0 !important;
}

.navigation__link .pagination li:hover {
  cursor: pointer;
}

.navigation__link .SelectSize {
  width: 100px;
}

.navigation__link .fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.navigation__link .pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.navigation__link .pagination > li > a,
.navigation__link .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.navigation__link .table-header {
  color: white;
  text-align: center;
}

.navigation__link .AlignCenter {
  text-align: center;
}

.navigation__link .AlignMiddle {
  align-items: center;
  display: flex;
}

.navigation__link .nav-pills .nav-link.active,
.navigation__link .nav-pills .show > .nav-link {
  background-color: #2fb6df;
  color: white;
}

.navigation__link .nav-pills .nav-link {
  color: black;
  border: 1px solid #dee2e6;
}

.navigation__link .centreAlign {
  text-align: center;
}

.navigation__link .head-title {
  text-align: left;
  color: #000000;
  line-height: 50px;
  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.navigation__link #close,
.navigation__link #ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}

.navigation__link #close:hover,
.navigation__link #ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}

@media only screen and (max-width: 600px) {
  .navigation__link .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}

.paddingRight {
  padding-right: 30px;
}

.UpperCase {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
  margin-left: 1rem;
}

.space {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  color: #2fb6df;
}

.underline1 {
  text-decoration: none;
  color: #1a529c;
}

.PrevBack {
  color: #2fb6df;
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon {
  color: #2fb6df;
  border-radius: 8%;
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.MarginRight {
  margin-right: 26px;
}

.PrevBack:hover {
  color: #1a529c;
}

.padDown {
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  #mainNav {
    display: none;
  }
}

.icd {
  height: 150px !important;
}

legend {
  font-size: medium;
}

.upercase {
  text-transform: uppercase;
}

select > option[value="null"] {
  display: none;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 44px !important;
  border-radius: 0px !important;
}

.custom-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.colorWhite {
  color: white;
}

.css-2b097c-container {
  z-index: 1000;
}

.atoc1 {
  z-index: 10000;
}

.borderColors {
  border: 1px solid #1a529c;
  height: auto;
  width: 300px;
  display: table;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: fixed;
  right: 0;
  top: 50px;
  z-index: 10000;
}

.borderColors1 {
  border: 1px solid #1a529c;
  height: auto;
  width: 300px;
  display: table;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: fixed;
  right: 40%;
  top: 20%;
  z-index: 10000;
  background-color: white;
}

.borderColors2 {
  border: 1px solid #1a529c;
  height: auto;
  width: 300px;
  display: table;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: fixed;
  right: 20%;
  top: 20%;
  z-index: 10000;
  background-color: white;
}

.fa-times-circle {
  font-size: 24px;
  top: 5px;
  right: 3px;
  float: right;
}

.colorError {
  padding-left: 10px;
}

.fa-pencil {
  font-size: 24px;
  right: 3px;
  width: 40px;
  margin-top: 7px;
}

.pencil1 {
  margin-top: 30px !important;
}

.custom-file-label::after {
  content: none !important;
}

input[placeholder] {
  text-overflow: ellipsis;
  font-size: 14px !important;
}

input::-moz-placeholder {
  text-overflow: ellipsis;
  font-size: 14px !important;
}

input:-moz-placeholder {
  text-overflow: ellipsis;
  font-size: 14px !important;
}

input:-ms-input-placeholder {
  text-overflow: ellipsis;
  font-size: 14px !important;
}

:focus::-webkit-input-placeholder {
  font-size: 12px !important;
}

:focus::-moz-placeholder {
  font-size: 12px !important;
}

:focus:-moz-placeholder {
  font-size: 12px !important;
}

:focus:-ms-input-placeholder {
  font-size: 12px !important;
}

.textFont {
  font-size: 14px !important;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.mce-notification-warning {
  display: none;
}

.width600 {
  max-width: 600px;
}

.circleColor {
  color: #1a529c;
}

.close {
  display: none;
}
/*# sourceMappingURL=create-deal.css.map */
/* mixin for multiline */
.navigation {
  position: fixed;
  background-color: white;
  color: black;
}

.navigation__link {
  display: block;
  color: #000000;
  text-decoration: none;
  padding: 1em;
  font-weight: 400;
  border: 1px solid #b3b3b3;
}

.navigation__link.active {
  color: white;
  background-color: #2fb6df;
}

.navigation__link .monitor_color {
  color: black;
}

.navigation__link button {
  font-size: 25px;
}

.navigation__link .tblnoline1 {
  border: 1px solid #b3b3b3;
  padding: 0px;
  font-family: "Roboto";
}

.navigation__link .tblnoline1 td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.navigation__link .tblnoline1 .td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
  padding: 0.75rem;
}

.navigation__link .tblnoline1 tr:nth-child(odd) {
  background: #2fb6df;
}

.navigation__link .tblnoline1 .tr {
  margin: 0 !important;
}

.navigation__link .tblnoline1 .tr:nth-child(odd) {
  background: #2fb6df;
}

.navigation__link .tblnoline1 .tr1 {
  background: #fff;
}

.navigation__link .tblnoline1 .tr2 {
  background: #2fb6df;
}

.navigation__link .red {
  color: red;
}

.navigation__link .underline {
  text-decoration: none;
  color: #2fb6df;
}

.navigation__link .x-scroller {
  overflow-x: auto;
}

.navigation__link .filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.navigation__link .paginate_button {
  border-radius: 0 !important;
}

.navigation__link .pagination li:hover {
  cursor: pointer;
}

.navigation__link .SelectSize {
  width: 100px;
}

.navigation__link .fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.navigation__link .pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.navigation__link .pagination > li > a,
.navigation__link .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.navigation__link .table-header {
  color: white;
  text-align: center;
}

.navigation__link .AlignCenter {
  text-align: center;
}

.navigation__link .AlignMiddle {
  align-items: center;
  display: flex;
}

.navigation__link .nav-pills .nav-link.active,
.navigation__link .nav-pills .show > .nav-link {
  background-color: #2fb6df;
  color: white;
}

.navigation__link .nav-pills .nav-link {
  color: black;
  border: 1px solid #dee2e6;
}

.navigation__link .centreAlign {
  text-align: center;
}

.navigation__link .head-title {
  text-align: left;
  color: #000000;
  line-height: 50px;
  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.navigation__link #close,
.navigation__link #ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}

.navigation__link #close:hover,
.navigation__link #ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}

@media only screen and (max-width: 600px) {
  .navigation__link .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}

.paddingRight {
  padding-right: 30px;
}

.UpperCase {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
  margin-left: 1rem;
}

.space {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  color: #2fb6df;
}

.underline1 {
  text-decoration: none;
  color: #1a529c;
}

.PrevBack {
  color: #2fb6df;
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon {
  color: #2fb6df;
  border-radius: 8%;
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon:hover {
  color: #1a529c;
}

.MarginRight {
  margin-right: 26px;
}

.PrevBack:hover {
  color: #1a529c;
}

.tblnoline1 .tr:nth-child(odd) {
  background: rgba(26, 182, 223, 0.2);
}

.padDown {
  margin-bottom: 30px;
}

.DateFont {
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  #mainNav {
    display: none;
  }
}

.tr {
  padding: 10px;
  margin: 0;
}

.tr1 {
  padding: 10px;
  margin: 0;
}

.tr2 {
  padding: 10px;
  margin: 0;
  background: #cfeff8;
}

.paddingSpace {
  padding-top: 4px !important;
  padding-bottom: 4px;
}

.tblnoline1 {
  border: 1px solid #dee2e6;
}

.font-size {
  font-size: 25px;
}

.profile {
  border-radius: 50%;
  width: 120px;
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.popup_Width-content {
  width: 500px !important;
}

.cam {
  font-size: 24px;
}

.mag {
  font-size: 20px;
  padding-top: 10px;
}

.popup_zindex-content {
  z-index: 100000 !important;
  width: 500px !important;
}

.widthEffect {
  width: "";
}

.underlines {
  text-decoration: underline !important;
}

.disabledCursor {
  cursor: default;
}

.buttonFloat {
  float: left;
}

.popup_zindexii-content {
  z-index: 100000 !important;
  width: 800px !important;
}

.popup_zindexiii-content {
  z-index: 100000 !important;
  width: 500px !important;
}
/*# sourceMappingURL=gpd.css.map */
/* No CSS *//*# sourceMappingURL=on-demand.css.map */
/* mixin for multiline */
.subscribelist {
  font-family: "Roboto";
  font-size: 16px;
  line-height: normal;
}

.subscribelist li {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.subscribelist li p {
  margin: 0;
}

.subscribelist li p span {
  color: #1f419b;
  font-weight: 500;
}

.subscribelist li img {
  margin-right: 15px;
  width: 50px;
}

.posac {
  top: 50%;
  text-align: center;
  width: 100%;
}

.blue-btn-n {
  background: #1a529c;
  color: white;
}

.blue-btn-n:hover {
  color: #fff;
}
/*# sourceMappingURL=gdm-home.css.map */
/* mixin for multiline */
.subscribelist {
  font-family: "Roboto";
  font-size: 16px;
  line-height: normal;
}

.subscribelist li {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.subscribelist li p {
  margin: 0;
}

.subscribelist li p span {
  color: #1f419b;
  font-weight: 500;
}

.subscribelist li img {
  margin-right: 15px;
  width: 50px;
}

.posac {
  top: 50%;
  text-align: center;
  width: 100%;
}
/*# sourceMappingURL=gdi-home.css.map */
.borderdesign {
  border-top: 1px dashed black;
  border-bottom: 1px solid black;
  border-left: 1px dashed black;
  border-right: 1px dashed black;
  background-color: #c9c9c9;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.borderdesignbody {
  border-top: 1px dashed black;
  border-bottom: 1px solid black;
  border-left: 1px dashed black;
  border-right: 1px dashed black;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
}

.y-scroller {
  overflow-y: auto;
  height: 800px;
}

/* mixin for multiline */
.navigation {
  position: fixed;
  background-color: white;
  color: black;
}

.navigation__link {
  display: block;
  color: #000000;
  text-decoration: none;
  padding: 1em;
  font-weight: 400;
  border: 1px solid #b3b3b3;
}

.navigation__link.active {
  color: white;
  background-color: #2fb6df;
}

.navigation__link .monitor_color {
  color: black;
}

.navigation__link button {
  font-size: 25px;
}

.navigation__link .tblnoline {
  border: 1px solid #b3b3b3;
  padding: 10px;
  font-family: "Roboto";
}

.navigation__link .tblnoline td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.navigation__link .tblnoline tr:nth-child(odd) {
  background: #2fb6df;
}

.navigation__link .red {
  color: red;
}

.navigation__link .underline {
  text-decoration: none;
  color: #2fb6df;
}

.navigation__link .x-scroller {
  overflow-x: auto;
}

.navigation__link .filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.navigation__link .paginate_button {
  border-radius: 0 !important;
}

.navigation__link .pagination li:hover {
  cursor: pointer;
}

.navigation__link .SelectSize {
  width: 100px;
}

.navigation__link .fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.navigation__link .pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.navigation__link .pagination > li > a,
.navigation__link .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.navigation__link .table-header {
  color: white;
  text-align: center;
}

.navigation__link .AlignCenter {
  text-align: center;
}

.navigation__link .AlignMiddle {
  align-items: center;
  display: flex;
}

.navigation__link .nav-pills .nav-link.active,
.navigation__link .nav-pills .show > .nav-link {
  background-color: #2fb6df;
  color: white;
}

.navigation__link .nav-pills .nav-link {
  color: black;
  border: 1px solid #dee2e6;
}

.navigation__link .centreAlign {
  text-align: center;
}

.navigation__link .head-title {
  text-align: left;
  color: #000000;
  line-height: 50px;
  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.navigation__link #close,
.navigation__link #ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}

.navigation__link #close:hover,
.navigation__link #ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}

@media only screen and (max-width: 600px) {
  .navigation__link .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}

.paddingRight {
  padding-right: 30px;
}

.UpperCase {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
  margin-left: 1rem;
}

.space {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  color: #2fb6df;
}

.underline1 {
  text-decoration: none;
  color: #1a529c;
}

.PrevBack {
  color: #2fb6df;
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon {
  color: #2fb6df;
  border-radius: 8%;
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.MarginRight {
  margin-right: 26px;
}

.PrevBack:hover {
  color: #1a529c;
}

.padDown {
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  #mainNav {
    display: none;
  }
}
/*# sourceMappingURL=fund-raising-deals.css.map */
/* mixin for multiline */
.navigation {
  position: fixed;
  background-color: white;
  color: black;
}

.navigation__link {
  display: block;
  color: #000000;
  text-decoration: none;
  padding: 1em;
  font-weight: 400;
  border: 1px solid #b3b3b3;
}

.navigation__link.active {
  color: white;
  background-color: #2fb6df;
}

.navigation__link .monitor_color {
  color: black;
}

.navigation__link button {
  font-size: 25px;
}

.navigation__link .tblnoline {
  border: 1px solid #b3b3b3;
  padding: 10px;
  font-family: "Roboto";
}

.navigation__link .tblnoline td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.navigation__link .tblnoline tr:nth-child(odd) {
  background: #2fb6df;
}

.navigation__link .red {
  color: red;
}

.navigation__link .underline {
  text-decoration: none;
  color: #2fb6df;
}

.navigation__link .x-scroller {
  overflow-x: auto;
}

.navigation__link .filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.navigation__link .paginate_button {
  border-radius: 0 !important;
}

.navigation__link .pagination li:hover {
  cursor: pointer;
}

.navigation__link .SelectSize {
  width: 100px;
}

.navigation__link .fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.navigation__link .pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.navigation__link .pagination > li > a,
.navigation__link .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.navigation__link .table-header {
  color: white;
  text-align: center;
}

.navigation__link .AlignCenter {
  text-align: center;
}

.navigation__link .AlignMiddle {
  align-items: center;
  display: flex;
}

.navigation__link .nav-pills .nav-link.active,
.navigation__link .nav-pills .show > .nav-link {
  background-color: #2fb6df;
  color: white;
}

.navigation__link .nav-pills .nav-link {
  color: black;
  border: 1px solid #dee2e6;
}

.navigation__link .centreAlign {
  text-align: center;
}

.navigation__link .head-title {
  text-align: left;
  color: #000000;
  line-height: 50px;
  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.navigation__link #close,
.navigation__link #ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}

.navigation__link #close:hover,
.navigation__link #ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}

@media only screen and (max-width: 600px) {
  .navigation__link .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}

.paddingRight {
  padding-right: 30px;
}

.UpperCase {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
  margin-left: 1rem;
}

.space {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  color: #2fb6df;
}

.underline1 {
  text-decoration: none;
  color: #1a529c;
}

.PrevBack {
  color: #2fb6df;
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon {
  color: #2fb6df;
  border-radius: 8%;
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.MarginRight {
  margin-right: 26px;
}

.PrevBack:hover {
  color: #1a529c;
}

.padDown {
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  #mainNav {
    display: none;
  }
}
/*# sourceMappingURL=debt-deals.css.map */
/* mixin for multiline */
.navigation {
  position: fixed;
  background-color: white;
  color: black;
}

.navigation__link {
  display: block;
  color: #000000;
  text-decoration: none;
  padding: 1em;
  font-weight: 400;
  border: 1px solid #b3b3b3;
}

.navigation__link.active {
  color: white;
  background-color: #2fb6df;
}

.navigation__link .monitor_color {
  color: black;
}

.navigation__link button {
  font-size: 25px;
}

.navigation__link .tblnoline {
  border: 1px solid #b3b3b3;
  padding: 10px;
  font-family: "Roboto";
}

.navigation__link .tblnoline td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.navigation__link .tblnoline tr:nth-child(odd) {
  background: #2fb6df;
}

.navigation__link .red {
  color: red;
}

.navigation__link .underline {
  text-decoration: none;
  color: #2fb6df;
}

.navigation__link .x-scroller {
  overflow-x: auto;
}

.navigation__link .filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.navigation__link .paginate_button {
  border-radius: 0 !important;
}

.navigation__link .pagination li:hover {
  cursor: pointer;
}

.navigation__link .SelectSize {
  width: 100px;
}

.navigation__link .fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.navigation__link .pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.navigation__link .pagination > li > a,
.navigation__link .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.navigation__link .table-header {
  color: white;
  text-align: center;
}

.navigation__link .AlignCenter {
  text-align: center;
}

.navigation__link .AlignMiddle {
  align-items: center;
  display: flex;
}

.navigation__link .nav-pills .nav-link.active,
.navigation__link .nav-pills .show > .nav-link {
  background-color: #2fb6df;
  color: white;
}

.navigation__link .nav-pills .nav-link {
  color: black;
  border: 1px solid #dee2e6;
}

.navigation__link .centreAlign {
  text-align: center;
}

.navigation__link .head-title {
  text-align: left;
  color: #000000;
  line-height: 50px;
  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.navigation__link #close,
.navigation__link #ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}

.navigation__link #close:hover,
.navigation__link #ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}

@media only screen and (max-width: 600px) {
  .navigation__link .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}

.paddingRight {
  padding-right: 30px;
}

.UpperCase {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
  margin-left: 1rem;
}

.space {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  color: #2fb6df;
}

.underline1 {
  text-decoration: none;
  color: #1a529c;
}

.PrevBack {
  color: #2fb6df;
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon {
  color: #2fb6df;
  border-radius: 8%;
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.MarginRight {
  margin-right: 26px;
}

.PrevBack:hover {
  color: #1a529c;
}

.padDown {
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  #mainNav {
    display: none;
  }
}
/*# sourceMappingURL=jv-deals.css.map */
/* mixin for multiline */
.navigation {
  position: fixed;
  background-color: white;
  color: black;
}

.navigation__link {
  display: block;
  color: #000000;
  text-decoration: none;
  padding: 1em;
  font-weight: 400;
  border: 1px solid #b3b3b3;
}

.navigation__link.active {
  color: white;
  background-color: #2fb6df;
}

.navigation__link .monitor_color {
  color: black;
}

.navigation__link button {
  font-size: 25px;
}

.navigation__link .tblnoline {
  border: 1px solid #b3b3b3;
  padding: 10px;
  font-family: "Roboto";
}

.navigation__link .tblnoline td {
  border-top: 0 !important;
  font-size: 17px;
  text-align: left;
}

.navigation__link .tblnoline tr:nth-child(odd) {
  background: #2fb6df;
}

.navigation__link .red {
  color: red;
}

.navigation__link .underline {
  text-decoration: none;
  color: #2fb6df;
}

.navigation__link .x-scroller {
  overflow-x: auto;
}

.navigation__link .filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.navigation__link .paginate_button {
  border-radius: 0 !important;
}

.navigation__link .pagination li:hover {
  cursor: pointer;
}

.navigation__link .SelectSize {
  width: 100px;
}

.navigation__link .fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.navigation__link .pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.navigation__link .pagination > li > a,
.navigation__link .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.navigation__link .table-header {
  color: white;
  text-align: center;
}

.navigation__link .AlignCenter {
  text-align: center;
}

.navigation__link .AlignMiddle {
  align-items: center;
  display: flex;
}

.navigation__link .nav-pills .nav-link.active,
.navigation__link .nav-pills .show > .nav-link {
  background-color: #2fb6df;
  color: white;
}

.navigation__link .nav-pills .nav-link {
  color: black;
  border: 1px solid #dee2e6;
}

.navigation__link .centreAlign {
  text-align: center;
}

.navigation__link .head-title {
  text-align: left;
  color: #000000;
  line-height: 50px;
  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.navigation__link #close,
.navigation__link #ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}

.navigation__link #close:hover,
.navigation__link #ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}

@media only screen and (max-width: 600px) {
  .navigation__link .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}

.paddingRight {
  padding-right: 30px;
}

.UpperCase {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
  margin-left: 1rem;
}

.space {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  color: #2fb6df;
}

.underline1 {
  text-decoration: none;
  color: #1a529c;
}

.PrevBack {
  color: #2fb6df;
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.Icon {
  color: #2fb6df;
  border-radius: 8%;
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #2fb6df;
  padding: 3px;
}

.MarginRight {
  margin-right: 26px;
}

.PrevBack:hover {
  color: #1a529c;
}

.padDown {
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  #mainNav {
    display: none;
  }
}

.activs {
  background-color: #a1e0f1;
}
/*# sourceMappingURL=re-deals.css.map */
/* No CSS *//*# sourceMappingURL=m-aDeals.css.map */
/* mixin for multiline */
.iis h4 {
  background: #1ab6df;
  color: #fff;
  padding: 12px;
  font-family: "Roboto";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
}

.iis .form-control {
  font-size: 0.9375rem;
  border-radius: 0;
}

.iis .c-dropdown {
  height: 125px;
  overflow-y: auto;
}

.navbar__link--active1 {
  color: #1a529c !important;
  background-color: transparent;
  font-weight: 600;
}
/*# sourceMappingURL=debt-deals-ql.css.map */
