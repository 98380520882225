.monitor_color {
  color: black;
}

button {
  font-size: 25px;
}

.red {
  color: red;
}

table thead tr th {
  background-color: #3e538c;

  position: relative;
}

.filter {
  position: absolute;
  border: 1px solid;
  top: 20px;
  background-color: #fff;
  width: 150px;
  right: 0;
  display: none;
  border-radius: 5%;
  padding: 11px;
  overflow-y: auto;
  z-index: 2;
}

.paginate_button {
  border-radius: 0 !important;
}
.pagination li:hover {
  cursor: pointer;
}
.SelectSize {
  width: 100px;
}

.fontSize {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}
.pagination-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.table-header {
  color: white;
}
#close,
#ok {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #5cc9e7;
  color: white;
}
#close:hover,
#ok:hover {
  margin-right: 9px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #3e538c;
  color: white;
}
@media only screen and (max-width: 600px) {
  .filter {
    width: 120px;
    padding: 5px;
    height: 200px;
  }
}
