.borderdesign {
  border-top: 1px dashed black;
  border-bottom: 1px solid black;
  border-left: 1px dashed black;
  border-right: 1px dashed black;
  background-color: #c9c9c9;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.borderdesignbody {
  border-top: 1px dashed black;
  border-bottom: 1px solid black;
  border-left: 1px dashed black;
  border-right: 1px dashed black;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
}

.y-scroller {
  overflow-y: auto;
  height: 800px;
}
